import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { List } from "linqts";
import { ToastsManager } from "ng6-toastr";
import { LocalStorage } from "../core/helper/localStorage";
import { BaseComponent } from "../core/component/Base.Component";
import { AppConstants } from "../app.constant";
import { InstituteService } from "../shell/forms/organizational-setup/setup/institute/institute.service";
import { SystemPolicyService } from "../shell/forms/organizational-setup/setup/systempolicy/systempolicy.service";
import { ImageService } from "../shell/shared/image.service";
import { GenEnum } from "../shell/shared/general.enum";
import { IxsloaderComponent } from "../components/ixsloader/ixsloader.component";

@Component({
  templateUrl: "userclients.component.html"
})
export class UserClientsComponent extends BaseComponent {
  model: any = {};
  userclients: any;
  localStorage: LocalStorage;
  selectedEntity;
  appName: string = "";

  @ViewChild(IxsloaderComponent) loader: IxsloaderComponent;

  constructor(
    private instituteService: InstituteService,
    private SystemPolicyservice: SystemPolicyService,
    private _GNImage: ImageService
  ) {
    super();

    this.appName = AppConstants.settings.appName;

    if (
      this.localStorage !== undefined &&
      this.localStorage.get("setselectedEntity")
    )
      this.localStorage.remove("setselectedEntity");

    if (!this.localStorage.get("token")) this.navigate("login");
  }

  async ngOnInit() {
    this.model.fullname = localStorage.getItem("Fullname").toUpperCase();
    this.model.username = localStorage.getItem("User").toUpperCase();
    this.model.clientstxt = localStorage.getItem("ClientStxt").toUpperCase();
    this.model.clientcode = localStorage.getItem("ClientCode").toUpperCase();
    this.model.userclients = localStorage.getItem("userclients");

    await this.getEntity();
  }

  setselectedEntity(selectedEntity) {
    var data = this.LookupData.instituteList.filter(
      o => o.Id === selectedEntity
    )[0];
    this.localStorage.set("ClientStxt", data.stxt);

    if (this.localStorage.get("setselectedEntity"))
      this.localStorage.remove("setselectedEntity");

    this.localStorage.set("selectedEntity", selectedEntity);
  }

  async checkentityExist() {
    var entityid = this.localStorage.get("selectedEntity");
    if (entityid) {
      await this.getPolicy(entityid);

      this.loader.startLoading();
      var dto = await this.instituteService.get(entityid);

      if (dto && dto.IsSuccess) {
        const legalEntity = dto.Data;
        this.loader.startLoading();
        this.localStorage.set("ShortName", legalEntity.shortname);

        var dtoImage = await this._GNImage.get(legalEntity.imageid);
        if (dtoImage && dtoImage.IsSuccess) {
          const gnimage = dtoImage.Data;
          if (gnimage.filename) {
            this.LookupData.imageSource =
              this.localStorage.get("OnlyUrl") +
              GenEnum.Image.imageUrl +
              gnimage.filename;
            this.localStorage.set("EntityLogo", this.LookupData.imageSource);
          }
        }
        this.navigate("home");
      } else {
        this.showErrors(dto.Errors);
        this.navigate("login");
      }
      this.loader.stopLoading();
    } else {
      this.navigate("login");
      this.loader.stopLoading();
    }
  }

  async getPolicy(entityid: any) {
    this.loader.startLoading();
    const dto = await this.SystemPolicyservice.getPolicy(entityid);
    if (dto && dto.IsSuccess && dto.Data) {
      AppConstants.policy.dateFormat = dto.Data.dateFormat;
      AppConstants.policy.timeFormat = dto.Data.timeFormat;
      AppConstants.policy.gridpagging = dto.Data.gridpagging;
      this.loader.stopLoading();
    } else {
      this.loader.stopLoading();
      console.log(dto.Errors);
    }
  }

  async getEntity() {
    var utuserents = JSON.parse(localStorage.getItem("utuserents"));
    var utapp = JSON.parse(localStorage.getItem("utapp"));

    if (utapp && utapp[0]) {
      const utappentitydtl = utapp[0].utappentitydtls;
      const utuserentity = utuserents;

      var entities = [];
      if (utappentitydtl) {
        utappentitydtl.forEach(element => {
          if (utuserentity.find(w => w.entityid === element.entityid)) {
            var entity = { Id: element.entityid, stxt: element.entityname };
            entities.push(entity);
          }
        });
      }
    }

    if (entities) this.LookupData.instituteList = entities;
  }
}
