import { GridSelectComponent } from "./../../../../../components/grid/editors/dropdown/gridselect.component";
import { AgGridComponent } from "./../../../../../components/grid/aggrid.component";
import { LovConfigs } from "./../../../../shared/lov.config";
import { CountryConfigs } from "./../../../../shared/country.config";
import { LovService } from "./../../../../shared/lov.service";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer,
  Input
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { NavComponent } from "../../../../../core/component/Nav.Component";

import { InstituteService } from "./institute.service";
import { CountryService } from "../../../../shared/country.service";
import { CityService } from "../../../../shared/city.service";
import { ImageService } from "../../../../shared/image.service";

import { PatternConstants } from "../../../../shared/pattern.constants";
import { GenEnum } from "../../../../shared/general.enum";

import { LookupComponent } from "../../../../../components/lookup/lookup.component";
import { LookupConfiguration } from "../../../../../components/lookup/lookup.config";

import {
  InstituteConfigs,
  campusTypeConfigs,
  cityConfigs
} from "./institute.config";

import {
  ImageCropperComponent,
  CropperSettings,
  Bounds
} from "ng2-img-cropper";
import { BsModalComponent } from "ng2-bs3-modal";
import { SystemPolicyService } from "../../../organizational-setup/setup/systempolicy/systempolicy.service";

declare var google: any;

@Component({
  selector: "institute",
  templateUrl: "institute.component.html"
})
export class InstituteComponent extends NavComponent<any> {
  columnDefs: any[];
  imageSource: string;
  myForm: FormGroup;
  campusTypeConfig: any;
  imgData: String;
  cityConfig: any;

  map: any;
  markers: any[];
  selectedPosition: any;
  infoWindow: any;

  @ViewChild("agGrid") mygrid: AgGridComponent;
  @ViewChild("fileUpload") public _fileUpload: ElementRef;
  @ViewChild("fileUpload2") public _fileUpload2: ElementRef;
  // Image variables
  @Input() canDelete: boolean = true;
  url: any;
  name: string;
  data1: any;
  cropperSettings1: CropperSettings;
  croppedWidth: number;
  croppedHeight: number;
  @ViewChild("cropper", undefined) cropper: ImageCropperComponent;

  // Modal
  @ViewChild("imageModal") public modal: BsModalComponent;
  @ViewChild("locationmap") public gmap: BsModalComponent;

  // Masks
  public mobilem = PatternConstants.pattern.mobile;
  public phonem = PatternConstants.pattern.phone;
  email = PatternConstants.pattern.email;
  webUrl = PatternConstants.pattern.url;

  constructor(
    private instituteService: InstituteService,
    private countryService: CountryService,
    private cityService: CityService,
    private imageService: ImageService,
    private systemPolicyService: SystemPolicyService,
    private renderer: Renderer,
    private lovservice: LovService
  ) {
    super(instituteService);
    this.lookupConfigs.lister = InstituteConfigs.lister;
    this.cropSettings();
    this.imageSource =
      this.localStorage.get("OnlyUrl") + GenEnum.Image.imageUrl;
    this.campusTypeConfig = campusTypeConfigs.lister;
    this.LookupData.cityConfig = cityConfigs.lister;
    this.LookupData.CountryConfig = CountryConfigs.lister;
  }

  async beforeSave() {
    this.mygrid.options.api;
    //this.model.ORGentitycontacts = this.LookupData.ORGentitycontacts;
  }

  async ngOnInit() {
    // this.model.ORGentitycontacts = [];
    const Entityid = this.localStorage.get("selectedEntity");
    const clientid = this.localStorage.get("clientid");
    this.instituteService.addDefaultCondition("clientid=@0 && Id=@1", [
      Number(clientid),
      Number(Entityid)
    ]);

    await this.initilizeForm();
    await this.getCountryByCode();
    await this.initAutocomplete();
    await this.getDefaultCountry();
    await this.getLovCampustype();
    await this.getContacttype();
    await this.gridConfig();
  }

  protected async beforeDisplay() {
    this.getCityByID(this.model.countryid);
  }

  protected async afterDisplay() {
    this.clearMarkers();
    this.getImage(this.model.imageid);

    this.getCityByID(this.model.countryid);
    this.addMarker();
  }

  protected async afterClear() {
    this.model.ORGentitycontacts = [];
    this.removePicture();
    this.myForm.controls["countryid"].setValue(
      this.LookupData.policyList[0].defaultctryid
    );
    this.getCityByID(this.LookupData.policyList[0].defaultctryid);
    this.myForm.controls["cityid"].setValue(
      this.LookupData.policyList[0].defaultcityid
    );
  }

  initilizeForm() {
    this.title = "Institute";
    this.myForm = this.fb.group({
      entityid: ["", []],
      stxt: ["", [Validators.required]],
      shortname: ["", []],
      ltxt: ["", []],
      contactperson: ["", [Validators.required]],
      contactno: ["", []],
      address: ["", []],
      countryid: ["", [Validators.required]],
      cityid: ["", [Validators.required]],
      location: ["empty", []],
      latitude: ["", []],
      longitude: ["", []],
      imageid: [, []],
      estdate: ["", [Validators.required]],
      lovcampustypeid: ["", [Validators.required]],
      allowprefix: ["", []]
    });
  }
  async getContacttype() {
    const dto = await this.lovservice.getContacttype();
    if (dto.IsSuccess) {
      this.LookupData.contacttype = dto.Data;
    } else {
      this.showErrors(dto.Errors);
    }
  }

  async gridConfig() {
    this.columnDefs = [
      {
        headerName: "Name",
        field: "stxt",
        width: 200,
        editable: true
      },
      {
        headerName: "Contact Type",
        field: "cntypid",
        width: 200,
        dropdownData: this.LookupData.contacttype,
        dataValueField: "Id",
        dataDisplayField: "stxt",
        cellRendererFramework: GridSelectComponent,
        config: LovConfigs.lister
      },
      {
        headerName: "Value",
        field: "value",
        width: 200,
        editable: true
      }
    ];
  }

  // Image & Crop Work

  cropSettings() {
    this.name = "Angular2";
    this.cropperSettings1 = new CropperSettings();
    // Preview box
    this.cropperSettings1.width = 128;
    this.cropperSettings1.height = 128;
    // Cropped Image Size
    this.cropperSettings1.croppedWidth = 640;
    this.cropperSettings1.croppedHeight = 320;
    // Crop Box size
    this.cropperSettings1.canvasWidth = 200;
    this.cropperSettings1.canvasHeight = 200;

    this.cropperSettings1.minWidth = 10;
    this.cropperSettings1.minHeight = 10;

    this.cropperSettings1.rounded = false;
    this.cropperSettings1.keepAspect = false;

    this.cropperSettings1.cropperDrawSettings.strokeColor =
      "rgba(255,255,255,1)";
    this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;

    this.data1 = {};
  }

  cropped(bounds: Bounds) {
    this.croppedHeight = bounds.bottom - bounds.top;
    this.croppedWidth = bounds.right - bounds.left;
  }

  fileChangeListener($event) {
    const image: any = new Image();
    const file = $event.target.files[0];
    const fileReader: FileReader = new FileReader();
    fileReader.onload = () => {
      image.onload = () => {
        this.cropper.setImage(image);
      };
      image.src = $event.target.result;
    };
    fileReader.readAsDataURL(file);
  }

  openImageModal(): boolean {
    this.modal.open();
    return false;
  }

  removePicture(): boolean {
    this.url = "";
    this.cropperSettings1 = new CropperSettings();
    this.data1 = {};
    this.myForm.controls["imageid"].setValue(undefined);
    this.canDelete = true;
    return false;
  }

  async uploadImage(url) {
    this.canDelete = false;
    this.imgData = "";
    this.imgData = this.data1.image;
    const code = this.imgData.indexOf(",");
    const baseImg = this.imgData.slice(code + 1, this.imgData.length);
    const dto = await this.imageService.uploadImage(baseImg);
    if (dto.IsSuccess) {
      this.url = this.imageSource + dto.Data.filename;
      this.myForm.controls["imageid"].setValue(dto.Data.Id);
      //   this.showSuccess('Image has been uploaded successfully');
      this.modal.close();
    } else {
      this.showErrors("Error in uploading image!");
    }
  }

  async getImage(id) {
    if (this.dto.Data.imageid) {
      this.url = "";
      this.canDelete = false;
      const dto = await this.imageService.getImageAsFile(id);
      if (dto.IsSuccess) {
        this.url = this.imageSource + dto.Data.filename;
      } else {
        console.log(dto.Errors);
      }
    } else {
      this.url = "";
      this.canDelete = true;
      //    this.toaster.info('No image data');
    }
  }

  // Web Url
  openUrl() {
    window.open("https://" + this.myForm.value.web, "_blank");
  }

  // Country DDL
  async getCountryByCode() {
    const dto = await this.countryService.getCountry();
    if (dto.IsSuccess) {
      this.LookupData.Countries = dto.Data;
    } else {
      console.log(dto.Errors);
    }
  }

  // City DDL
  async getCityByID(countryid: any) {
    const dto = await this.cityService.getCityByID(countryid);
    if (dto.IsSuccess) {
      this.LookupData.cityList = dto.Data;
    } else {
      console.log(dto.Errors);
    }
  }

  // Google Maps API pure work
  googleMap() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.9179934, lng: 67.0976998 },
      zoom: 11,
      mapTypeId: "roadmap"
    });
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.infoWindow = new google.maps.InfoWindow();
        this.infoWindow.setPosition(pos);
        this.infoWindow.setContent("Location found.");
        this.infoWindow.open(this.map);
        this.map.setCenter(pos);
      });
    } else {
      this.showWarnings("Unable to access location", "Access denied");
    }
  }

  initAutocomplete() {
    this.googleMap();
    this.getCurrentLocation();

    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input");
    const searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    this.map.addListener("bounds_changed", () => {
      searchBox.setBounds(this.map.getBounds());
    });

    this.markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // Clear out the old markers.
      this.markers.forEach(marker => {
        marker.setMap(null);
      });

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      places.forEach(place => {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };
        // Create a marker for each place.
        this.markers.push(
          new google.maps.Marker({
            map: this.map,
            icon: icon,
            title: place.name,
            position: place.geometry.location
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });

    this.mapClick();
  }

  mapClick() {
    google.maps.event.addListener(this.map, "click", event => {
      this.deleteMarkers(false);
      this.selectedPosition = event.latLng;
      this.myForm.controls["latitude"].setValue(this.selectedPosition.lat());
      this.myForm.controls["longitude"].setValue(this.selectedPosition.lng());
      this.addMarker();
    });
  }

  addMarker() {
    this.deleteMarkers(false);
    if (this.myForm.value.latitude && this.myForm.value.longitude) {
      this.markers = [
        new google.maps.Marker({
          position: {
            lat: this.myForm.value.latitude,
            lng: this.myForm.value.longitude
          },
          map: this.map
        })
      ];
      const center = new google.maps.LatLng(
        this.myForm.value.latitude,
        this.myForm.value.longitude
      );
      this.map.panTo(center);
      this.map.setZoom(17);
    } else {
      this.getCurrentLocation();
      this.showWarnings("No location saved");
    }
  }

  setMapOnAll(map) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  clearMarkers() {
    this.setMapOnAll(null);
  }

  deleteMarkers(isdelete: boolean) {
    this.clearMarkers();
    this.markers = [];
    if (isdelete) {
      this.myForm.controls["longitude"].setValue("");
      this.myForm.controls["latitude"].setValue("");
    }
  }

  async getDefaultCountry() {
    const dto = await this.systemPolicyService.getAll();
    if (dto.IsSuccess) {
      this.LookupData.policyList = dto.Data;
    } else {
      this.showErrors(dto.Errors);
    }
  }

  async getLovCampustype() {
    const dto = await this.lovservice.getCampustype();
    if (dto.IsSuccess) {
      this.LookupData.Lov = dto.Data;
    } else {
      this.showErrors(dto.Errors);
    }
  }
  async openGooglemap() {
    this.gmap.open("lg");
  }
}
