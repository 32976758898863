import { moodConfig } from "../../components/config";

export const GenEnum = {


    ComplainantType: {
        Transporter: 26,
        Driver: 27,
        Guardian: 28,
        Student: 68,
    },

    Months: [
        { Id: 1, stxt: 'January' },
        { Id: 2, stxt: 'February' },
        { Id: 3, stxt: 'March' },
        { Id: 4, stxt: 'April' },
        { Id: 5, stxt: 'May' },
        { Id: 6, stxt: 'June' },
        { Id: 7, stxt: 'July' },
        { Id: 8, stxt: 'August' },
        { Id: 9, stxt: 'September' },
        { Id: 10, stxt: 'October' },
        { Id: 11, stxt: 'November' },
        { Id: 12, stxt: 'December' },
    ],

    Image: {
        imageUrl: 'Images/Actual/',
    },
    PeriodUnit: {
        Day: 41,
        Month: 42,
        Year: 43,
    },
    FeeFor: {
        School: 92,
        Student: 93,
        Course: 94,
        Application: 296
    },
    TripCategory: {
        Up: 8,
        Down: 9,
    },
    CheckList: [
        { Id: true, stxt: 'Yes' },
        { Id: false, stxt: 'No' },

    ],
    Country: [
        { Id: 166, stxt: 'Pakistan' },

    ],
    MemberShipType: {
        Student: 190,
        Staff: 191,
    },
    ScheduleFor: {
        Entity: 270,
        Department: 271,
        Employee: 272,
    },

    AccrualUnit: {
        Yearly: 257,
        Accrual: 258,
    },


    // Courier_Company:{

    //     TCS: 222,
    //     MandP:223,
    //     Leopards: 224,
    //     FedEx: 225,
    // },

    Postal_Type: {
        Dispatch: 226,
        Receive: 227
    },
    EmployeeCategory: {
        Mangement: 267,
        Admin: 268,
        Teacher: 269
    },
    HRMenu: <moodConfig[]>[
        { moodId: 1, moodValue: "Additional Information", moodIcon: 'fa fa-adjust', MoodGroup: "Action" },

    ],
    Fomenu: <moodConfig[]>[
        { moodId: 1, moodValue: "Follow Up", moodIcon: 'fa fa-adjust', MoodGroup: " " },

    ],
    HRAdditionalInfo: <any>[
        { id: 0, Value: "Back", icon: 'fa fa-arrow-left', visible: true, class: "btn-back" },
        { id: 1, Value: "Address", icon: 'fa fa-home', visible: true, class: "" },
        { id: 2, Value: "Employement", icon: 'fa fa-cog', visible: false, class: "" },
        { id: 3, Value: "Education", icon: 'fa fa-graduation-cap', visible: true, class: "" },
        { id: 4, Value: "Skill", icon: 'fa fa-signal', visible: true, class: "" },
        { id: 5, Value: "Position Assign", icon: 'fa fa-tags', visible: true, class: "" },
        { id: 6, Value: "Bank Account", icon: 'fa fa-university', visible: true, class: "" },
        { id: 7, Value: "Relation", icon: 'fa fa-gears', visible: true, class: "" },
        { id: 8, Value: "Card", icon: 'fa fa-credit-card', visible: true, class: "" },
        { id: 9, Value: "Experience", icon: 'fa fa-certificate', visible: true, class: "" },
        { id: 10, Value: "Disease", icon: 'fa fa-user-md', visible: true, class: "" },
    ],


    StdMenu: <moodConfig[]>[
        { moodId: 1, moodValue: "Additional Information", moodIcon: 'fa fa-adjust', MoodGroup: " " },

    ],
    StdAdditionalInfo: <any>[
        { id: 1, Value: "Academic", icon: 'fa fa-book', visible: true },
        { id: 2, Value: "Fee Payments", icon: 'fa fa-credit-card', visible: true },
        { id: 3, Value: "Student History", icon: 'fa fa-mortar-board', visible: true },
        { id: 4, Value: "Grade History", icon: 'fa fa-line-chart', visible: true },
        { id: 5, Value: "Transporter", icon: 'fa fa-subway', visible: true },
        { id: 6, Value: "Disease", icon: 'fa fa-user', visible: true },
        { id: 7, Value: "Fee Discount", icon: 'fa fa-user', visible: true },
    ],

    ApplicationMenu: <moodConfig[]>[
        { moodId: 1, moodValue: "Additional Information", moodIcon: 'fa fa-adjust', MoodGroup: " " },
    ],
    AppplicationInfo: <moodConfig[]>[
        { moodId: 1, moodValue: "Assessment", moodIcon: 'fa fa-book', MoodGroup: " " },
        { moodId: 2, moodValue: "Interview Schedule", moodIcon: 'fa fa-user', MoodGroup: " " },
        { moodId: 3, moodValue: "Convert to Admission", moodIcon: 'fa fa-object-ungroup', MoodGroup: " " }

    ],

    Complainant_Type: {
        Transporter: 26,
        Driver: 27,
        Guardian: 28,
        Student: 68,

    },

    ComplainerType: {
        Parent: 170,
        Student: 171,
        Driver: 172,
        Staff: 173,
        Teacher: 295,
    },

    Return_Media: <moodConfig[]>[
        { moodId: 1, moodValue: 'Return', moodIcon: 'fa fa-check', MoodGroup: 'Actions' },
        { moodId: 2, moodValue: 'Cancel', moodIcon: 'fa fa-remove (alias)', MoodGroup: 'Actions' },
    ],

    FEE_Nature: {
        School: 60,
        Transport: 61,
        Hostel: 62,

    },

    Respose_Type: {
        In_Process: 162,
        Hold: 163,
        Intrested: 164,
        Not_Intrested: 165,
    },

    Status: {
        Open: 160,
        Closed: 161,
    },

    BASON: {
        Amount: 273,
        Percentage: 274,
    },
}

