import { Injectable } from '@angular/core';
import { AppService } from "src/app/core/service/Base.Service";

@Injectable()
export class SMStudentinfoService extends AppService<any> {

    constructor() {
        super('Studentinfo');
    }

    async getStudent(entityid) {
        if (entityid) {
            const result = await this.get('Student', [entityid]);
            return result;
        }
    }
}