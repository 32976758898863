import { Injectable } from '@angular/core';
import { AppService } from '../../../../../core/service/Base.Service';
import { List } from 'linqts';

@Injectable()
export class SystemPolicyService extends AppService<any> {

    constructor() {
        super('Policy');
    }

    async GetPolicy() {
        // var rtnobj: any = {};

        // const result = await this.getAll();
        // if (result)
        //     rtnobj = (new List<any>(result.Data).FirstOrDefault());
        // return rtnobj;
        const result = await this.get('');
        return result;
    }

    async getsystemDaysPolicy(entityid, ttid) {

        const result = await this.get('SystemDays', [entityid, ttid]);
        return result;
    }
    async getPolicy(entityid) {
        const result = await this.get('Policy', [entityid]);
        return result;
    }

}