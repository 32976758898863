import {
    Component,
    ViewChild,
    ElementRef,
    Renderer,
    AfterViewInit
  } from "@angular/core";
  import { ToastOptions, ToastyService, ToastyConfig } from "ng2-toasty";
  import { AppConstants } from "../../app.constant";
  import { ToolbarEvent } from "../../core/events/toolbar.event";
  import { LocalStorage } from "../../core/helper/localStorage";
  import { Subscription } from "rxjs/Subscription";
  declare var require: any
  
  @Component({
    selector: "ixs-loader",
    templateUrl: "ixsloader.component.html",
    styles: ["ixsloader.css"]
  })
  export class IxsloaderComponent implements AfterViewInit {
    interval: number;
    toolbarSubscription: Subscription;
    toolbarEvent: ToolbarEvent;
    protected localstorage: LocalStorage;
    ShortName: string;
    EntityLogo: string;
    eduManlogoUrl: string;
    private msg;
  
    ngAfterViewInit(): void {
      if (this.element && this.element.nativeElement)
        this.element.nativeElement.children[0].children[0].style.display = "none";
    }
    async ngOnInit() {
      this.localstorage = AppConstants.injector.get(LocalStorage);
  
      this.eduManlogoUrl = require('../../../assets/Images/loader-logo.png')
      if (this.localstorage) {
  
        this.ShortName = this.localstorage.get("ShortName");
        this.EntityLogo = this.localstorage.get("EntityLogo");
  
      }
  
    }
    constructor(
      private toastyService: ToastyService,
      private element: ElementRef
    ) {
      this.toolbarEvent = AppConstants.injector.get(ToolbarEvent);
  
      this.toolbarSubscription = this.toolbarEvent
        .click()
        .subscribe(async arg => {
          switch (arg.key) {
            case "start":
              await this.startLoading();
              break;
          }
          switch (arg.key) {
            case "stop":
              await this.stopLoading();
              break;
          }
        });
    }
  
    startLoading() {
  
      this.element.nativeElement.children[0].children[0].style.display = "block";
      var toastOptions: ToastOptions = {
        title: "",
        msg: `
        
  
        
        <div class="loaderBox ">
        
              <div class='loader'>
              </div>
              <img class='imgcenter' src='" + {{eduManlogoUrl}} + "' onerror=this.src='../../../assets/Images/loader-logo.png' alt 'No Image Found'>
                  <div>
                      <div class="spinner">
                         <span class='loading-text'>EduMan</span>
                          <div class="bounce1"></div>
                          <div class="bounce2"></div>
                          <div class="bounce3"></div>
                      </div>
                  </div>
          </div>
          `,
  
        // msg: "<div class='loaderBox'><div class='circle-loader'><img class='imgcenter img-circle' src='" +
        //     this.EntityLogo + "' onerror=this.src='assets/AdminLTE_New/images/block.png' alt 'No Image Found'><div class='circle'></div><div class='circle'></div><div class='circle'></div><div class='circle'></div><div class='circle'></div></div><div><div><div class='wait' style='margin-top: 55%;'>Please Wait...</div><div id='overlayDiv'></div></div></div> </div>",
  
        showClose: false,
        theme: "bootstrap",
        timeout: 0
      };
      this.toastyService.default(toastOptions);
      ;
    }
  
    stopLoading() {
      ;
      this.toastyService.clearAll();
      this.element.nativeElement.children[0].children[0].style.display = "none";
    }
  
    ngOnDestroy() {
      if (this.toolbarSubscription) {
        this.toolbarSubscription.unsubscribe();
      }
    }
  }
  