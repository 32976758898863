import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SmComponent } from "./sm.component";
import { NavigationBarComponent } from "../shell-components/navigation-bar.component";
import {
  SidebarLeftComponent,
  FilterPipe
} from "../shell-components/sidebar-left.component";
import { SidebarRightComponent } from "../shell-components/sidebar-right.component";
import { PageContainerComponent } from "../shell-components/page-container.component";
import { FooterComponent } from "../shell-components/footer.component";

import { AppContainerComponent } from "../formcontainer/formcontainer.component";

import { ComponentModule } from "../../components/component.module";

// Forms Module
import { HomeModule } from "../forms/home/home.module";
import { RouterModule } from "@angular/router";
import { SmRoutingModule } from "./sm.routing";
import { InstituteModule } from "../forms/organizational-setup/setup/institute/institute.module";
import { StudentReportModule } from "src/app/Reports/student-report/student-report.module";
import { ExamscheduleModule } from "src/app/Reports/Academic/Exam/ExamSchedule/examschedule.module";
import { AdmissionenquirydetailModule } from "src/app/Reports/Front Office/Admission-Enquiry-Detail/admissionenquirydetail.module";
import { AdmissionenquiryModule } from "src/app/Reports/Front Office/Admission-Enquiry/admissionenquiry.module";
import { VisitorbookdetailModule } from "src/app/Reports/Front Office/Visitor-Book-Detail/visitorbookdetail.module";
import { SmFeechallanhdrModule } from "src/app/Reports/sm-feechallanhdr/sm-feechallanhdr.module";
import { CallregisterdetailModule } from "src/app/Reports/Front Office/Call-Register-Detail/callregisterdetail.module";
import { PostalregisterdetailModule } from "src/app/Reports/Front Office/Postal-Register-Detail/postalregisterdetail.module";
import { MenuScreenModule } from "../forms/menu-screen/menu-screen.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ComponentModule,

    // Forms
    RouterModule,
    HomeModule,
    SmRoutingModule,
    InstituteModule,
    StudentReportModule,
    ExamscheduleModule,
    AdmissionenquirydetailModule,
    AdmissionenquiryModule,
    VisitorbookdetailModule,
    SmFeechallanhdrModule,
    CallregisterdetailModule,
    PostalregisterdetailModule,
    MenuScreenModule
  ],
  declarations: [
    SmComponent,
    AppContainerComponent,
    NavigationBarComponent,
    SidebarLeftComponent,
    SidebarRightComponent,
    PageContainerComponent,

    FooterComponent,
    FilterPipe
  ],
  providers: []
})
export class SmModule {}
