import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  templateUrl: "./sm.component.html",
  styleUrls: ["./sm.component.css"]
})
export class SmComponent implements OnInit {
  // BODY TAG OPTIONS:
  // =================
  // Apply one or more of the following classes to get the
  // desired effect
  // |---------------------------------------------------------|
  // | SKINS         | skin-blue                               |
  // |               | skin-black                              |
  // |               | skin-purple                             |
  // |               | skin-yellow                             |
  // |               | skin-red                                |
  // |               | skin-green                              |
  // |---------------------------------------------------------|
  // |LAYOUT OPTIONS | fixed                                   |
  // |               | layout-boxed                            |
  // |               | layout-top-nav                          |
  // |               | sidebar-collapse                        |
  // |               | sidebar-mini                            |
  // |---------------------------------------------------------|
  skin: string = "skin-blue";
  layoutOptions: string = "sidebar-mini";

  body = document.getElementsByTagName("body")[0];

  constructor(private router: Router) {}

  ngOnInit(): void {
    //add the the body classes
    this.body.classList.add(this.skin);
    this.body.classList.add(this.layoutOptions);
  }

  ngOnDestroy(): void {
    //remove the the body classes
    this.body.classList.remove(this.skin);
    this.body.classList.remove(this.layoutOptions);
  }
}
