import {AfterViewInit, Component, ViewChild, ViewContainerRef} from "@angular/core";

import { ICellEditorAngularComp, AgRendererComponent } from "ag-grid-angular";
import * as moment from 'moment'
import { ICellRendererParams } from "ag-grid/dist/lib/rendering/cellRenderers/iCellRenderer";
import { IAfterGuiAttachedParams } from "ag-grid/dist/lib/interfaces/iComponent";


@Component({
    selector: 'date-editor-cell',
    template: `<ixs-date  #input (change)="valueChange(data)"  [(ngModel)]="value" ></ixs-date>`,
    styles :['border:none;'] 
})
export class GridDatePickerComponent implements AgRendererComponent  {
    
    refresh(params: any): boolean {
        
       return true;
    }
    agInit(params: ICellRendererParams): void {
        
        const element = this.input.element.nativeElement.children[1];
        if(element){
            element.classList.remove('form-control');
            element.style.border="none";
        }
        this.params = params;
        this.input.disabled = !Boolean(this.params.colDef.editable === undefined ? true :this.params.colDef.editable);
        this.value = this.params.value;
        
        const element1:HTMLElement = this.input.element.nativeElement;
        if (element1) {
            
            element1.getElementsByTagName('input')[0].style.backgroundColor = "transparent";

            
        }
       
    }
    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
        
        
    }
    private params: any;
    public value: any;
    private cancelBeforeStart: boolean = false;

    @ViewChild('input') public input;


   

    getValue(): any {
        
        return  this.params.value;
    }

    valueChange(data){
        
        this.params.setValue(this.value);
    }

    

   

   

   

}