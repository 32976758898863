import { LookupConfiguration } from '../../../../../components/lookup/lookup.config';

export const InstituteConfigs = {
    lister: <LookupConfiguration[]>[

        {
            name: 'lov.stxt',
            caption: 'Campus Type',
            width: '150px'
        },
        {
            name: 'estdate',
            caption: 'Established Date',
            width: '130px',
            type: 'date'
        },
        {
            name: 'stxt',
            caption: 'Institute Name',
            width: '250px'
        },
        {
            name: 'contactperson',
            caption: 'Contact Person',
            width: '180px'
        },
        {
            name: 'contactno',
            caption: 'Contact No',
            width: '100px'
        },
        {
            name: 'address',
            caption: 'Address',
            width: '300px'
        },
        {
            name: 'Country.countryname',
            caption: 'Country',
            width: '150px'
        },
        {
            name: 'City.citystxt',
            caption: 'City',
            width: '150px'
        },


    ]



};
export const campusTypeConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'code',
            caption: 'Code'
        },
        {
            name: 'stxt',
            caption: 'Campus Type'
        },
        {
            name: 'ltxt',
            caption: 'Description'
        }

    ]

};

export const cityConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'citycode',
            caption: 'Code'
        },
        {
            name: 'citystxt',
            caption: 'City'
        },
        {
            name: 'Country.countrycode',
            caption: 'Country'
        },
        {
            name: 'dialcode',
            caption: 'Dial Code'
        }

    ]

};

