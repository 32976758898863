// Angular Imports
import { NgModule } from "@angular/core";

// This Module's Components
import { CallregisterdetailComponent } from "./callregisterdetail.component";
import { BrowserModule } from "@angular/platform-browser";
import { ReportViewerModule } from "src/app/report-viewer/report-viewer.module";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IxsReportModule } from "src/app/components/ixs-report/ixs-report.module";
import { ComponentModule } from "src/app/components/component.module";

@NgModule({
  imports: [
    BrowserModule,
    ReportViewerModule,
    NgxErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentModule,
    IxsReportModule
  ],
  declarations: [CallregisterdetailComponent],
  exports: [CallregisterdetailComponent]
})
export class CallregisterdetailModule {}
