import { Injectable } from '@angular/core';
import { AppService } from '../../core/service/Base.Service';
import { DataTransferObject } from '../../core/entities/DataTransferObject';

@Injectable()
export class ImageService extends AppService<any> {

    constructor() {
        super('Image');
    }

    async uploadImage(data) {

        const dto: DataTransferObject<any> = <DataTransferObject<any>>{};
        dto.Data = {
            image: data
        };
        const result = await this.post('image', dto);
        return result;
    }

    async getImage(id) {
        const result = await this.get('image', [id, 'Actual']);
        return result;
    }

    async getImageAsFile(id) {
        const result = await this.get('', id);
        return result;
    }
}

