import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular/main";
import { moodConfig } from "../../../config";
import { List } from "linqts";

@Component({
    selector: 'moodeditor-cell',
    template: `
        <div #container class="mood" tabindex="0" (keydown)="onKeyDown($event)">
         <div *ngFor ="let grp of groups" class="box box-default boxWrapper">
        <div class="box-header with-border ">
            <h3 class="box-title pull-left">{{grp}} </h3>
            
            
        </div>
        <i   *ngFor ="let item of filterData(grp)" [id]="item[valueMember]"  (click)="onClick($event.target.id)"  [ngClass]=" item['moodIcon'] ? item['moodIcon'] :'fa fa-tasks'">{{item[displayMember] ? '    '+ item[displayMember] : '' }}</i> 
        </div>
                         
        </div>
    `,
    styles: [`
        .mood {
            border-radius: 15px;
            // border: 1px solid grey;
            // background: #e6e6e6;
            padding: 15px;
            text-align: left;
            display: inline-block;
            outline: none;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            
        }
       
        i{
                margin: 10px;
                padding: 10px;
                color:#287fc4;
                background-color:transparent;
        }

        i:hover{
        cursor:pointer;
        color:#fff;
        background-color:#1764a1
    }
    .boxWrapper{
        min-height: 65px;
        max-width: 300px;
    }

    .selected{
        color:#fff;
        background-color:#1764a1
    }
    .default{
         color:black;
         background-color:transparent
    }
    `]
})
export class MoodEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;

    @ViewChild('container', { read: ViewContainerRef }) public container;
    private data: [moodConfig];
    private valueMember;
    private displayMember;
    private column: any;
    public value: any;
    private groups: any = [];

    private async loadData() {

        this.valueMember = this.column.colDef.dataValueField;
        this.displayMember = this.column.colDef.dataDisplayField;
        this.data = this.column.colDef.dropdownData;

        //  this.groups = new List<moodConfig>(this.data).Select(x => <moodConfig>{
        //    MoodGroup : x['MoodGroup']
        // }).Distinct().ToArray();
        this.groups = (new List<moodConfig>(this.data)).Select(o => o.MoodGroup).Distinct().ToArray();

    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

        //this.container.element.nativeElement.focus();
    }

    async agInit(params: any) {

        this.params = params;
        this.value = this.params.value;
        this.column = this.params.column;
        await this.loadData();
        this.setClass(this.value);
    }

    getValue(): any {
        return this.value;
    }

    isPopup(): boolean {
        return true;
    }



    toggleMood(): void {
        this.value = this.params.value;
    }

    onClick(value) {

        this.value = value
        this.setClass(value);
        this.params.api.stopEditing();
    }

    onKeyDown(event): void {
        let key = event.which || event.keyCode;
        if (key == 37 ||  // left
            key == 39) {  // right
            this.toggleMood();
            event.stopPropagation();
        }
    }

    filterData(grp): Array<moodConfig> {

        return this.data.filter(o => o.MoodGroup === grp);
    }

    private setClass(id) {

        if (id && !Boolean(this.column.colDef.isMenu)) {
            var tags: NodeListOf<HTMLElement> = this.container.element.nativeElement.getElementsByTagName("i");

            for (var index = 0; index < tags.length; index++) {



                if (Number(tags[index].id) === Number(id)) {

                    tags[index].classList.add('selected');
                }
                else {
                    tags[index].classList.add('default');
                }

            }
        }
    }
}