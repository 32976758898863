import { Component, OnInit, Input } from '@angular/core';

import { ToolbarEvent } from '../../core/events/toolbar.event';

@Component({
    selector: 'ixs-toolbar',
    templateUrl: 'toolbar.component.html'
})
export class ToolbarComponent implements OnInit {

    @Input()
    showAdd :Boolean;
    @Input()
    showDelete:Boolean;

    constructor(public toolbarEvent: ToolbarEvent) {

    }

    ngOnInit() { }
}
