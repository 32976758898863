import { Component, OnInit, Injectable } from '@angular/core';
import { UtService } from './Base.Service';
import { DataTransferObject } from '../entities/DataTransferObject';

@Injectable()
export class UserService extends UtService<any> {
    constructor() {
        super('utuser');
    }

    async login(username: string, password: string) {

        const dto: DataTransferObject<any> = <DataTransferObject<any>>{};
        dto.Data = {
            user: {
                username: username,
                password: password
            }
        };

        // const result = await this.post('login', dto);
        const result = await this.put('loginV3', dto);

        if (result.IsSuccess) {
            this.headers['Authorization'] = 'bearer '.concat(result.Data.Session.token);

            if (result && result.Data && result.Data.User && result.Data.User.utrole)
                localStorage.setItem('userrole', JSON.stringify(result.Data.User.utrole));
        }

        return result;
    }

}
