// import { style } from "../../../../node_modules/@angular/core";

export const GenEnum = {
  ComplainantType: {
    Transporter: 26,
    Driver: 27,
    Guardian: 28,
    Student: 68
  },

  Months: [
    { Id: 1, stxt: "January" },
    { Id: 2, stxt: "February" },
    { Id: 3, stxt: "March" },
    { Id: 4, stxt: "April" },
    { Id: 5, stxt: "May" },
    { Id: 6, stxt: "June" },
    { Id: 7, stxt: "July" },
    { Id: 8, stxt: "August" },
    { Id: 9, stxt: "September" },
    { Id: 10, stxt: "October" },
    { Id: 11, stxt: "November" },
    { Id: 12, stxt: "December" }
  ],

  Image: {
    imageUrl: "Images/Actual/"
  },
  PeriodUnit: {
    Day: 41,
    Month: 42,
    Year: 43
  },
  FeeFor: {
    School: 92,
    Student: 93,
    Course: 94,
    Application: 296
  },
  TripCategory: {
    Up: 8,
    Down: 9
  },
  CheckList: [{ Id: true, stxt: "Yes" }, { Id: false, stxt: "No" }],
  Country: [{ Id: 166, stxt: "Pakistan" }],
  MemberShipType: {
    Student: 190,
    Staff: 191
  },
  ScheduleFor: {
    Entity: 270,
    Department: 271,
    Employee: 272
  },

  AccrualUnit: {
    Yearly: 257,
    Accrual: 258
  },

  SendTo: {
    Parents: 306,
    Students: 307,
    Teacher: 308,
    Driver: 309
  },

  // Courier_Company:{

  //     TCS: 222,
  //     MandP:223,
  //     Leopards: 224,
  //     FedEx: 225,
  // },

  Postal_Type: {
    Dispatch: 226,
    Receive: 227
  },
  EmployeeCategory: {
    Mangement: 267,
    Admin: 268,
    Teacher: 269
  },

  Complainant_Type: {
    Transporter: 26,
    Driver: 27,
    Guardian: 28,
    Student: 68
  },

  ComplainerType: {
    Parent: 170,
    Student: 171,
    Driver: 172,
    Staff: 173,
    Teacher: 295
  },

  FEE_Nature: {
    School: 60,
    Transport: 61,
    Hostel: 62
  },

  Respose_Type: {
    In_Process: 162,
    Hold: 163,
    Intrested: 164,
    Not_Intrested: 165
  },

  Complaint_Status: {
    Pending: 77,
    InProcess: 78,
    Closed: 79
  },

  Status: {
    Open: 160,
    Closed: 161
  },

  BASON: {
    Amount: 273,
    Percentage: 274
  },

  Result: {
    Pass: 297,
    Failed: 298
  },

  User_Type: {
    Admin: 9,
    Employee: 10,
    Parent: 11,
    Student: 12,
    Driver: 13
  },

  QuestionBaseOn: {
    QuestionnaireTime: 331,
    QuestionTime: 332,
    NoTimeLimit: 333
  },

  Fee_Status: {
    Unpaid: "Unpaid",
    Paid: "Paid",
    Exempt: "Exempt",
    Partial_Paid: "Partial Paid"
  },

  SMAtnActivity: {
    Present: 1,
    Absent: 2,
    Leave: 3,
    Tardy: 4,
    Exempeted: 5,
    Holiday: 6
  },

  WeekDay: [
    { Id: 99, stxt: "Sunday", JSid: 0 },
    { Id: 100, stxt: "Monday", JSid: 1 },
    { Id: 101, stxt: "Tuesday", JSid: 2 },
    { Id: 102, stxt: "Wednesday", JSid: 3 },
    { Id: 103, stxt: "Thursday", JSid: 4 },
    { Id: 104, stxt: "Friday", JSid: 5 },
    { Id: 105, stxt: "Saturday", JSid: 6 }
  ],

  weekDays: {
    Sunday: 99,
    Monday: 100,
    Tuesday: 101,
    Wednesday: 102,
    Thursday: 103,
    Friday: 104,
    Saturday: 105
  },
  MenuCategory: {
    Screens: 24,
    Reports: 25
  }
};
