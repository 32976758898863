import { Injector } from "@angular/core";

export const AppConstants = {
  urls: {
    app: {
      login: "login",
      apptype: {
        Report: 25,
      },
    },
    api: {
       base: 'https://ut.edu-man.com/ut/api/',
      // base: "http://95.216.234.30:1007/utuat/api/", // Live UT
      //base: "http://192.168.1.177:4321/ut/api/",

      application: "",
      reportNGUrl: "https://reports.edu-man.com/#/",
      //reportNGUrl: "http://uatreports.edu-man.com/#/",
      // reportNGUrl: "http://reports.skobus.com/#/",
      //reportUrl: "http://portal.edu-man.com/WebForms/DevExReportViewer.aspx"
      // reportUrl: "http://portal.skobus.com/WebForms/DevExReportViewer.aspx"
      //reportUrl: "http://95.216.234.30:6009/WebForms/DevExReportViewer.aspx"
      reportUrl: "https://portal.edu-man.com/WebForms/DevExReportViewer.aspx",
    },
  },
  settings: {
    appName: "EDU-MAN",
    appCode: "SB",
    instanceid: 19,
    instancecode: "SM004",
    dateFormat: "dd-MMM-yyyy",
    timeFormat: "HH:mm:ss",
    dateFormatforString: "DD-MMM-YYYY",
    timeFormatforString: "hh:mm:ss A",
    timeFormatTile: "hh:mm A",
    dateAndTime: "YYYY-MM-DDTHH:mm",
  },
  policy: {
    dateFormat: "",
    timeFormat: "",
    gridpagging: false,
    defaultcityid: 0,
    defaultctryid: 0,
  },
  injector: <Injector>{},
};
