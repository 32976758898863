import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/observable";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";

@Injectable()
export class ToolbarEvent {
  private toolBarEvent: Subject<ToolbarEventArg>;
  constructor() {
    this.toolBarEvent = new Subject<ToolbarEventArg>();
  }

  async boardcast(key: string) {
    const arg: ToolbarEventArg = <ToolbarEventArg>{};
    arg.key = key;

    await this.toolBarEvent.next(arg);
  }

  click(): Observable<ToolbarEventArg> {
    return this.toolBarEvent.asObservable();
  }
}

export interface ToolbarEventArg {
  key: string;
}
