import { LookupConfiguration } from "../../components/lookup/lookup.config";


export const CountryConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'Id',
            caption: 'Country ID'
        },
        {
            name: 'countrycode',
            caption: 'Country Code'
        },
        {
            name: 'countryname',
            caption: 'Country Name'
        },

        {
            name: 'dialcode',
            caption: 'Dial Code'
        },
    ]
};


