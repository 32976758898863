import { ToastOptions } from "ng6-toastr";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { NavComponent } from "../../../core/component/Nav.Component";
import { PercentPipe } from "@angular/common";
import { BaseComponent } from "src/app/core/component/Base.Component";
import { InstituteService } from "../organizational-setup/setup/institute/institute.service";

@Component({
  selector: "home",
  templateUrl: "home.component.html"
})
export class HomeComponent extends NavComponent<any> implements AfterViewInit {
  ngAfterViewInit() {
    // this.showToolbar(false);
  }

  model: any = {};

  constructor(private _InstituteService: InstituteService) {
    super(_InstituteService);
  }

  async OnInit() {
    await this.initilizeForm();
  }

  initilizeForm() {
    // this.title = "Dashboard";
    this.setTitleNIcon("Dashboard","");
    // this.showToolbar(false);
  }
}
