import { Injectable } from '@angular/core';
import { AppService } from '../../core/service/Base.Service';
import { DataTransferObject } from '../../core/entities/DataTransferObject';

@Injectable()
export class CountryService extends AppService<any> {

    constructor() {
        super('Country');
    }

    async getCountry() {
        const result = await this.get('');
        return result;

    }

     async getCountryByCode(countrycode: any) {
        const result = await this.get('Country', countrycode);
        return result;

    }

}


