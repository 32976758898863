import { Component, OnInit } from '@angular/core';

import { ToolbarEvent } from '../../core/events/toolbar.event';


@Component({
	selector: 'formcontainer',
	templateUrl: 'formcontainer.component.html'
})

export class FormContainerComponent implements OnInit {

	constructor(private toolbarEvent: ToolbarEvent) {
	}

	ngOnInit() { }
}
