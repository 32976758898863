import { Injectable, Component } from '@angular/core';
import { AppService } from "src/app/core/service/Base.Service";

@Injectable()
export class LovService extends AppService<any>{


    constructor() {
        super('lov');
    }

    async getResponse() {
        const result = await this.get('List', ['RSPTP']);
        return result;
    }

    async getCourierCompany() {
        const result = await this.get('List', ['COURI']);
        return result;
    }

    async getPostalType() {
        const result = await this.get('List', ['PSDOC']);
        return result;
    }

    async getFeeStatus() {
        const result = await this.get('List', ['FSTS']);
        return result;
    }

    // async getGender() {
    //     const result = await this.get('List', ['GEND']);
    //     return result;
    // }

    // async  getjobStatus() {
    //     const result = await this.get('List', ['JBSTS']);
    //     return result;
    // }

    // async getComplaintType() {
    //     const result = await this.get('List', ['CTYPE']);
    //     return result;
    // }

    // async getComplainantType() {
    //     const result = await this.get('List', ['PTYPE']);
    //     return result;
    // }

    // async getBloodGroup() {
    //     const result = await this.get('List', ['BLOOD']);
    //     return result;
    // }

    // async getShift() {
    //     const result = await this.get('List', ['SHIFT']);
    //     return result;
    // }

    // async getPeriodUnit() {
    //     const result = await this.get('List', ['PUNIT']);
    //     return result;
    // }

    // async getLocationType() {
    //     const result = await this.get('List', ['LOCTP']);
    //     return result;
    // }

    // async getTrip() {
    //     const result = await this.get('List', ['TRIP']);
    //     return result;
    // }

    // async getTripCategory() {
    //     const result = await this.get('List', ['TRIPC']);
    //     return result;
    // }

    // async getFeeOccurance() {
    //     const result = await this.get('List', ['FOCCU']);
    //     return result;
    // }

    // async getSessionStatus() {
    //     const result = await this.get('List', ['NSESS']);
    //     return result;
    // }

    // async getFeeNature() {
    //     const result = await this.get('List', ['FNATU']);
    //     return result;
    // }

    // async getVehicleCategory() {
    //     const result = await this.get('List', ['VCAT']);
    //     return result;
    // }
    // async getEmployeeType() {
    //     const result = await this.get('List', ['EMPTP']);
    //     return result;
    // }

    // async getAuthor() {
    //     const result = await this.get('List', ['AUTHT']);
    //     return result;
    // }

    // async getAuthors() {
    //     const result = await this.get('List', ['AUTHR']);
    //     return result;
    // }
    // async getPositionType() {
    //     const result = await this.get('List', ['PSTYP']);
    //     return result;
    // }
    // async getLevel() {
    //     const result = await this.get('List', ['LEVEL']);
    //     return result;
    // }
    // async getPeriodunit() {
    //     const result = await this.get('List', ['PUNIT']);
    //     return result;
    // }
    // async getCardType() {
    //     const result = await this.get('List', ['CRDTP']);
    //     return result;
    // }
    // async getBusinessUnit() {
    //     const result = await this.get('List', ['BSNAT']);
    //     return result;
    // }
    // async getDocumentType() {
    //     const result = await this.get('List', ['EMPDC']);
    //     return result;
    // }
    // async getContacttype() {
    //     const result = await this.get('List', ['CNTYP']);
    //     return result;
    // }
    async getCallType() {
        const result = await this.get('List', ['CALLT']);
        return result;
    }
    async getCallMedium() {
        const result = await this.get('List', ['CALLM']);
        return result;
    }
    // async getFinetype() {
    //     const result = await this.get('List', ['FNTYP']);
    //     return result;
    // }
    // async getBaseon() {
    //     const result = await this.get('List', ['BASON']);
    //     return result;
    // }
    // async getIncrementtype() {
    //     const result = await this.get('List', ['FNINC']);
    //     return result;
    // }

    // async getEmployeeCategory() {
    //     const result = await this.get('List', ['ECAT']);
    //     return result;
    // }

    // async getGrouptype() {
    //     const result = await this.get('List', ['FNATU']);
    //     return result;
    // }


    // getYear(start, end): any[] {

    //     var item = [];

    //     for (var i = start; i <= end; i++) {

    //         item.push(i);
    //     }

    //     return item;
    // }


    //     async getVehicleTrip(vehicleid: any, tripcatid: any) {
    //         const result = await this.get('VehicleTrip', [vehicleid, tripcatid]);
    //         return result;
    //     }

    async getFeeFor() {
        const result = await this.get('List', ['FFOR']);
        return result;
    }

    //     async getCampustype() {
    //         const result = await this.get('List', ['CPTY']);
    //         return result;
    //     }
    //     async getWeekdays() {
    //         const result = await this.get('List', ['WDAY']);
    //         return result;
    //     }
    //     async getApplyon() {
    //         const result = await this.get('List', ['APLYH']);
    //         return result;
    //     }
    //     
    //     async getStatus() {
    //         const result = await this.get('List', ['STATS']);
    //         return result;
    //     }

    //     async getComplaintStatus() {
    //         const result = await this.get('List', ['CMSTS']);
    //         return result;
    //     }

    //     async GetCertificateFor() {
    //         const result = await this.get('List', ['CFFOR']);
    //         return result;
    //     }

    //     async getmaritialStatus() {
    //         const result = await this.get('List', ['MRSTS']);
    //         return result;
    //     }
    //     async getActiveInActive() {
    //         const result = await this.get('List', ['AACT']);
    //         return result;

    //     }

    //     async getdocumentsType() {
    //         const result = await this.get('List', ['DCTYP']);
    //         return result;

    //     }

    //     async getLicenseType() {
    //         const result = await this.get('List', ['LICTP']);
    //         return result;
    //     }

    //     async getMarital() {
    //         const result = await this.get('List', ['MRSTS']);
    //         return result;
    //     }

    //     async getLicenseCategory() {
    //         const result = await this.get('List', ['LICCT']);
    //         return result;
    //     }

    //     async getMembertype() {
    //         const result = await this.get('List', ['MEMTP']);
    //         return result;
    //     }

    //     async getAddresstype() {
    //         const result = await this.get('List', ['ADDTP']);
    //         return result;
    //     }
    //     async getBaseOn() {
    //         const result = await this.get('List', ['BASON']);
    //         return result;
    //     }
    //     async getDisCat() {
    //         const result = await this.get('List', ['DISCT']);
    //         return result;
    //     }
    //     async getDisTyp() {
    //         const result = await this.get('List', ['DISTP']);
    //         return result;
    //     }
    //     async getMeet() {
    //         const result = await this.get('List', ['PTMWT']);
    //         return result;
    //     }
    //     async getPolicytype() {
    //         const result = await this.get('List', ['ATPOL']);
    //         return result;
    //     }
    //     async getPenaltytype() {
    //         const result = await this.get('List', ['PENTP']);
    //         return result;
    //     }

    //     async getAttendancetype() {
    //         const result = await this.get('List', ['ATNTP']);
    //         return result;
    //     }
    //     async getLeaveunit() {
    //         const result = await this.get('List', ['LVUNT']);
    //         return result;
    //     }
    //     async getEntitleon() {
    //         const result = await this.get('List', ['LVTIT']);
    //         return result;
    //     }
    //     async getAccrualunit() {
    //         const result = await this.get('List', ['ACUNT']);
    //         return result;
    //     }

    //     async getgenratedChallan() {
    //         const result = await this.get('List', ['CGNRT']);
    //         return result;
    //     }

    //     async getSchedulefor() {
    //         const result = await this.get('List', ['SCHFR']);
    //         return result;
    //     }
    //     async getResult() {
    //         const result = await this.get('List', ['RESLT']);
    //         return result;
    //     } 

    //     async getComplainertype() {
    //         const result = await this.get('List', ['CMPTP']);
    //         return result;
    //     }

    //     async getpayMOde() {
    //         const result = await this.get('List', ['PAYMD']);
    //         return result;
    //     }
    //     async getinterviewFor() {
    //         const result = await this.get('List', ['INFR']);
    //         return result;
    //     }

    //     async getShiftType() {
    //         const result = await this.get('List', ['SFTT']);
    //         return result;
    //     }
    //     async getScheduletype() {
    //         const result = await this.get('List', ['SCTY']);
    //         return result;
    //     }

    //     async getMessageType() {
    //         const result = await this.get('List', ['MSGTP']);
    //         return result;
    //     }

    //     async getSendTo() {
    //         const result = await this.get('List', ['SNTTO']);
    //         return result;
    //     }

    //     async getQuestionCategory() {
    //         const result = await this.get('List', ['QCAT']);
    //         return result;
    //     }

    //     async getInputType() {
    //         const result = await this.get('List', ['ANSIN']);
    //         return result;
    //     }

    async getTerm() {
        const result = await this.get('List', ['TERM']);
        return result;
    }

    //     async getQuestionView() {
    //         const result = await this.get('List', ['QVIEW']);
    //         return result;
    //     }

    //     async getQuestionBaseOn() {
    //         const result = await this.get('List', ['QBASE']);
    //         return result;
    //     }


    //     async getQuestionSequence() {
    //         const result = await this.get('List', ['QSEQ']);
    //         return result;
    //     }

    //     async getResultType() {
    //         const result = await this.get('List', ['RESLT']);
    //         return result;
    //     }
    // async getFeeCategory() {
    //         const result = await this.get('List', ['FCAT']);
    //         return result;
    //     }
}
