// import { NgModule, ErrorHandler } from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CommonModule } from '@angular/common';

// import { BaseComponent } from './component/Base.Component';
// import { NavComponent } from './component/Nav.Component';

// // Services
// import { UserService } from './service/user.service';

// import { ToolbarEvent } from './events/toolbar.event';
// import { Broadcast } from './events/broadcast';
// import { HttpHelper } from './helper/http.Helper';
// import { LocalStorage } from './helper/localStorage';
// import { AuthGuard } from './helper/auth.guard';
// import { AppService, UtService } from './service/Base.Service';
// import { GlobalErrorHandler } from './helper/error.Handler';
// import { MenusService } from "./service/menus.service";

// @NgModule({
//     imports: [
//         CommonModule,
//         FormsModule,
//         ReactiveFormsModule],
//     providers: [
//         ToolbarEvent,
//         Broadcast,
//         UserService,
//         HttpHelper,
//         LocalStorage,
//         AuthGuard,
//         AppService,
//         UtService,
//         MenusService,
//         {
//             provide: ErrorHandler,
//             useClass: GlobalErrorHandler
//         }
//     ],
// })

// export class CoreModule {

// }


import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { BaseComponent } from './component/Base.Component';
import { NavComponent } from './component/Nav.Component';

// Services
import { UserService } from './service/user.service';

import { ToolbarEvent } from './events/toolbar.event';
import { Broadcast } from './events/broadcast';
import { HttpHelper } from './helper/http.Helper';
import { LocalStorage } from './helper/localStorage';
import { AuthGuard } from './helper/auth.guard';
import { AppService, UtService } from './service/Base.Service';
import { GlobalErrorHandler } from './helper/error.Handler';
import {MenusService  } from "../core/service/menus.service";
import { SyncDataService } from "./service/syncdata.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule],
    providers: [
        ToolbarEvent,
        Broadcast,
        UserService,
        HttpHelper,
        LocalStorage,
        AuthGuard,
        AppService,
        MenusService,
        UtService,
        SyncDataService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }
    ],
})

export class CoreModule {

}
