import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/app.constant';

@Component({
	
	selector: "app-footer",
	templateUrl: '../shell-templates/footer.component.html'
})

export class FooterComponent {

	constructor(
	) {
		

	 }

	ngOnInit(): void {
	}
}
