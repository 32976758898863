import { Component, OnInit, ReflectiveInjector, Injector, ViewChild } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ToastsManager } from 'ng6-toastr';

import { ToolbarEvent } from '../../core/events/toolbar.event';
import { BaseService } from '../service/Base.Service';
import { DataTransferObject } from '../entities/DataTransferObject';
import { AppConstants } from '../../app.constant';
import { LocalStorage } from '../helper/localStorage';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from './Base.Component';
import { Subject } from 'rxjs/Subject';
import { Broadcast } from '../events/broadcast';
import { MessageBoxComponent } from '../../components/messagebox/messagebox.component';
import { MessageBoxSettings } from '../../components/messagebox/messagebox.config';

export abstract class ContainerComponent extends BaseComponent {
    protected myForm: FormGroup;

    public title: string;
    public showTb: Boolean;
    public showAdd:Boolean;
    public showDelete:Boolean;
    public menu:string;
    public submenu:string;
    public titleIcon: string;

    @ViewChild('ixsmessagebox')
    public messageBox: MessageBoxComponent;

    constructor() {
        super();
        this.menu = '';
        this.submenu='';

        this.broadcast.observable<FormGroup>('formgroup').subscribe(x => {
            this.myForm = x.value;
        });

        this.broadcast.observable<string>('title').subscribe(x => {
            this.title = x.value;
        });

        this.broadcast.observable<MessageBoxSettings>('showMessage').subscribe(x => {
            this.messageBox.open(x.value);
        });

        this.broadcast.observable<Boolean>('showToolbar').subscribe(x => {
            this.showTb = x.value;
        });

         this.broadcast.observable<Boolean>('hideadd').subscribe(x => {
            this.showAdd = x.value;
        });

         this.broadcast.observable<Boolean>('hidedelete').subscribe(x => {
            this.showDelete = x.value;
        });

        this.broadcast.observable<string>('menu').subscribe(x => {
            this.menu = x.value;
        });
        this.broadcast.observable<string>('submenu').subscribe(x => {
            this.submenu = x.value;
        });
        this.broadcast.observable<string>('titleIcon').subscribe(x => {
            this.titleIcon = x.value;
        });
    }

    async ngOnInit() {
    }
}
