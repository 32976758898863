import { IXSBsModalComponent } from "./ixsmodal/ixsmodal.component";

export interface MapConfiguration {
  field: string;
  mapField: string;
}

export const Enum = {
  Mode: {
    Default: 0,
    New: 1,
    Display: 2,
    Edit: 3
  }
};

export const GridType = {
  inputGrid: 0,
  readOnlyGrid: 1,
  selectionGrid: 2,
  actionsGrid: 3
};
export interface modalArgs {
  cancel: boolean;
  modal: IXSBsModalComponent;
  rowData: any;
}

export interface moodConfig {
  MoodGroup: string;
  moodId: any;
  moodValue: any;
  moodIcon: string;
}

export const addRow = {
  top: "top",
  current: "current",
  bottom: "bottom"
};
export const inputType = {
  text: "text",
  mobile: "mobile",
  phone: "phone",
  cnic: "cnic",
  license: "license",
  time: "time"
};

export interface customButton {
  name: string;
  caption: string;
  icon: string;
}

export interface reportConfig {
  path: string;
  component: any;
}
