import { Injectable, Injector, ReflectiveInjector } from "@angular/core";

import { HttpHelper } from "../helper/http.Helper";
import { DataTransferObject } from "../entities/DataTransferObject";
import { AppConstants } from "../../app.constant";
import { LocalStorage } from "../helper/localStorage";
import { List } from "linqts";

@Injectable()
export abstract class BaseService<T extends any> {
  protected headers: {} = {};

  private httpHelper: HttpHelper;
  protected localStorage: LocalStorage;
  protected attributes: any = {};
  protected attribProp: any = {};

  constructor(public controller: string, private url: string) {
    this.httpHelper = AppConstants.injector.get(HttpHelper);
    this.localStorage = AppConstants.injector.get(LocalStorage);

    const token = this.localStorage.get("token");

    this.headers["Content-Type"] = "application/json";
    this.headers["Authorization"] = token;
    this.headers["Access-Control-Allow-Origin"] = "*";
  }

  addDefaultCondition(key: string, params?: Array<any>) {
    this.attributes[key] = params;
    this.setAttributes();
  }

  setAttributes() {
    this.attribProp["DefaultConditions"] = this.attributes;
    //this.attribProp['DocType'] = 0;

    this.headers["attributes"] = JSON.stringify(this.attribProp);
  }

  clearCondition() {
    delete this.attribProp["DefaultConditions"];
    //this.attribProp['DocType'] = 0;
    this.attributes = {};
    delete this.headers["attributes"];
  }

  async getNext(nextOf: any) {
    const dto = await this.get("next", nextOf);

    return dto;
  }

  async getPrevious(previousOf: any) {
    const dto = await this.get("previous", previousOf);

    return dto;
  }

  async getLast() {
    const dto = await this.get("last");

    return dto;
  }

  async getFirst() {
    const dto = await this.get("first");

    return dto;
  }

  async Get(key: any) {
    const dto = await this.get("", key);

    return dto;
  }

  async getAll() {
    const dto = await this.get();

    return dto;
  }

  async save(dto: DataTransferObject<T>) {
    let result;
    if (dto.KeyValue) {
      result = await this.put("", dto);
    } else {
      result = await this.post("", dto);
    }

    return result;
  }

  async delete(Id: any) {
    const dto = <DataTransferObject<T>>{
      KeyValue: Id,
      ReturnObject: true
    };

    const result = await this.del("", dto);
    return result;
  }

  // HTTP Method
  async get(
    method?: string,
    params?: Array<any>
  ): Promise<DataTransferObject<T>> {
    return await this.httpHelper.get<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers
    );
  }

  async post(
    method: string,
    body?: DataTransferObject<T>,
    params?: Array<any>
  ): Promise<DataTransferObject<T>> {
    return await this.httpHelper.post<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers,
      body
    );
  }

  async put(
    method: string,
    body?: DataTransferObject<T>,
    params?: Array<any>
  ): Promise<DataTransferObject<T>> {
    return await this.httpHelper.put<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers,
      body
    );
  }

  async del(
    method: string,
    body?: DataTransferObject<T>,
    params?: Array<any>
  ): Promise<DataTransferObject<T>> {
    return await this.httpHelper.delete<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers,
      body
    );
  }

  //  Private Method
  private getUrl(method: string, params?: any): string {
    
    let result: string;
    result = this.url
      .concat(this.controller)
      .concat("/v2/")
      .concat(method || "");

    if (params) {
      result = result
        .concat("/")
        .concat(Array.isArray(params) ? params.join("/") : params);
    }

    return result;
  }
}

@Injectable()
export class UtService<T extends any> extends BaseService<T> {
  constructor(private utController: string) {
    super(utController, AppConstants.urls.api.base);
  }
}

@Injectable()
export class AppService<T extends any> extends BaseService<T> {
  constructor(private appController: string) {
    super(appController, localStorage.getItem("apiUrl"));
  }
}
