import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { LoginComponent } from './login.component';

import { LoginRoutingModule } from './login-routing.module';
import { ComponentModule } from '../components/component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoginRoutingModule,
    ComponentModule
  ],
  declarations: [
    LoginComponent,
  ],
  providers: [
  ]
})
export class LoginModule { }
