import {
  Component,
  Input,
  ViewChild,
  forwardRef,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer,
  EventEmitter,
  Output,
  ContentChild,
  QueryList,
  ViewChildren,
  AfterViewInit
} from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  SelectControlValueAccessor
} from "@angular/forms";
import { SELECT_VALUE_ACCESSOR } from "@angular/forms/src/directives/select_control_value_accessor";
import { inputType } from "../config";
import { PatternConstants } from "../../shell/shared/pattern.constants";
import { ValueFormatterService } from "ag-grid";

@Component({
  selector: "ixs-textbox",
  templateUrl: "ixstextbox.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IXSTextBoxComponent),
      multi: true
    }
  ]
})
export class IXSTextBoxComponent
  implements ControlValueAccessor, AfterViewInit {
  ngAfterViewInit(): void {}

  @ViewChild("input") ixsTextBox: any;
  constructor() {}

  // //for the purpose of fire event on lister select event
  // public triggerChanged() {
  //     let event = new CustomEvent('change', { bubbles: true });
  //     this.renderer.invokeElementMethod(this.element.nativeElement, 'dispatchEvent', [event]);
  // }

  private innerValue: any = "";

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  get value(): any {
    return this.innerValue;
  }

  @Input()
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @Input()
  caption: string;

  @Input()
  disabled: boolean = false;

  @Input()
  showCaption: boolean = true;

  @Input()
  placeholder: string = "";

  private inputtype: string = inputType.text;

  @Input()
  get inputType(): string {
    return this.inputtype;
  }

  set inputType(value) {
    this.inputtype = value;

    if (this.inputtype === inputType.mobile) {
      this.inputMask = { mask: PatternConstants.pattern.mobile };
    } else if (this.inputtype === inputType.cnic) {
      this.inputMask = { mask: PatternConstants.pattern.cnic };
    } else if (this.inputtype === inputType.phone) {
      this.inputMask = { mask: PatternConstants.pattern.phone };
    } else if (this.inputtype === inputType.license) {
      this.inputMask = { mask: PatternConstants.pattern.license };
    } else if (this.inputtype === inputType.time) {
      this.inputMask = { mask: PatternConstants.pattern.time };
    } else {
      this.inputtype = null;
    }
  }

  private inputMask: any = null;

  @Input()
  maxlength: number;

  @Input()
  minlength: number;

  @Input()
  position: number = 1;

  private _fieldWidth = "input-group col-lg-3 col-md-3 col-sm-9 col-xs-9";

  @Input()
  get fieldWidth(): string {
    return this._fieldWidth;
  }
  set fieldWidth(value: string) {
    
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._fieldWidth =
          "input-group col-lg-" +
          (colSplit[0] ? colSplit[0] : "3") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "3") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3");
      }
    }
  }

  private _captionWidth = "col-lg-1 col-md-1 col-sm-3 col-xs-3 col-form-label";

  @Input()
  get captionWidth(): string {
    return this._captionWidth;
  }
  set captionWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._captionWidth =
          "col-lg-" +
          (colSplit[0] ? colSplit[0] : "1") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "1") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          "col-form-label";
      }
    }
  }
}
