import {
  Component,
  Input,
  ViewChild,
  forwardRef,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer,
  EventEmitter,
  Output,
  ContentChild,
  QueryList,
  ViewChildren
} from "@angular/core";
import { BsModalComponent } from "ng2-bs3-modal";
import { LookupConfiguration, LookupResult } from "../lookup/lookup.config";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  SelectControlValueAccessor
} from "@angular/forms";
import { SELECT_VALUE_ACCESSOR } from "@angular/forms/src/directives/select_control_value_accessor";
import { Observable } from "rxjs/observable";
import { LookupComponent } from "../lookup/lookup.component";
import { Subscription } from "rxjs/Subscription";
import { Broadcast } from "../../core/events/broadcast";
import { AppConstants } from "../../app.constant";
import { IXSBsModalComponent } from "../ixsmodal/ixsmodal.component";
import { NavModalComponent } from "../../core/component/NavModal.Component";
import { Enum } from "../config";

@Component({
  selector: "ixs-dropdown",
  templateUrl: "dropdown.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IXSDropdownComponent),
      multi: true
    }
  ],
  styleUrls: ["dropdown.css"]
})
export class IXSDropdownComponent implements ControlValueAccessor {
  selectedItem: any[];
  @ContentChild(forwardRef(() => IXSBsModalComponent))
  myscreens: IXSBsModalComponent;

  constructor(private element: ElementRef, private renderer: Renderer) {
    this.broadcast = AppConstants.injector.get(Broadcast);

    this.lookupSubscription = this.broadcast
      .observable<LookupResult>("lookup")
      .subscribe(arg => {
        switch (arg.value.lookupId) {
          case this.fieldName:
            this.value = arg.value.data[this.valueMember];
            this.triggerChanged();
            break;
        }
      });
  }

  //for the purpose of fire event on lister select event
  public triggerChanged() {
    let event = new CustomEvent("change", { bubbles: true });
    this.renderer.invokeElementMethod(
      this.element.nativeElement,
      "dispatchEvent",
      [event]
    );
  }

  private innerValue: any = "";

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @ViewChild("lookup") public lookup: LookupComponent;

  @Input()
  config: LookupConfiguration[];

  @Input()
  valueMember: string;

  @Input()
  displayMember: string;

  @Input()
  caption: string;

  @Input()
  lookupCaption: string;

  @Input()
  fieldName: string;

  _data: any[];
  get data(): any[] {
    return this._data;
  }

  @Input()
  set data(value: any[]) {
    this._data = value;
  }

  @Input()
  disabled: boolean = false;

  open() {
    this.lookup.LookupId = this.fieldName;
    this.lookup.open();
  }

  @Input()
  showCaption: boolean = true;

  @Input()
  showTool: boolean = false;

  private lookupSubscription: Subscription;
  protected broadcast: Broadcast;

  private async openSubScreen() {
    if (this.value && this.myscreens && this.myscreens.screen.service) {
      this.myscreens.screen.service.addDefaultCondition(
        this.valueMember + "=@0",
        [Number(this.value)]
      );
      this.myscreens.displayLastRecord = true;
      this.myscreens.mode = Enum.Mode.Edit;
      await this.myscreens.open();
    }
  }

  private async newSubScreen() {
    if (this.myscreens) {
      this.myscreens.displayLastRecord = false;
      this.myscreens.mode = Enum.Mode.New;
      if (this.myscreens.screen.service) {
        this.myscreens.screen.service.clearCondition();
      }
      await this.myscreens.screen.clear();

      await this.myscreens.open();
    }
  }

  async Modelchange() {
    this.value = Number(this.value);
  }
  ondelete(event) {
    this.value = "";
  }
  @Input()
  position: number = 1;

  private _fieldWidth = "input-group col-lg-3 col-md-3 col-sm-9 col-xs-9";

  @Input()
  get fieldWidth(): string {
    return this._fieldWidth;
  }
  set fieldWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._fieldWidth =
          "input-group col-lg-" +
          (colSplit[0] ? colSplit[0] : "3") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "3") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3");
      }
    }
  }

  private _captionWidth = "col-lg-1 col-md-1 col-sm-3 col-xs-3 col-form-label";

  @Input()
  get captionWidth(): string {
    return this._captionWidth;
  }
  set captionWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._captionWidth =
          "col-lg-" +
          (colSplit[0] ? colSplit[0] : "1") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "1") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          "col-form-label";
      }
    }
  }

  @Input()
  showNone: boolean = false;
}
