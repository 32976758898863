import { AfterViewInit, Component, ViewChild, ViewContainerRef, ContentChild, forwardRef } from "@angular/core";

import { ICellEditorAngularComp, AgRendererComponent } from "ag-grid-angular";
import * as moment from 'moment'
import { ICellRendererParams } from "ag-grid/dist/lib/rendering/cellRenderers/iCellRenderer";
import { IAfterGuiAttachedParams } from "ag-grid/dist/lib/interfaces/iComponent";
import { IXSBsModalComponent } from "../../../ixsmodal/ixsmodal.component";
import { AgGridComponent } from "../../aggrid.component";
import { Enum, modalArgs } from "../../../config";
import { RowNode } from "ag-grid/dist/lib/entities/rowNode";
import { RowDataTransaction } from "ag-grid/dist/lib/rowModels/inMemory/inMemoryRowModel";
import { BaseComponent } from "../../../../core/component/Base.Component";
import { MessageBoxResultType, MessageBoxResult } from "../../../messagebox/messagebox.config";
import { Subscription } from "rxjs/Subscription";


@Component({
    selector: 'actions-editor-cell',
    template: `

    <a (click)="viewScreen()" title="View"><i class="fa fa-credit-card flat-icon"></i></a> <a (click)="editScreen()"  title="Edit"><i class="fa fa-edit flat-icon "></i></a> <a (click)="delete()" title="Delete"><i class="fa fa-trash flat-icon "></i></a>
    `
    ,
    styles: ['a:hover{cursor:pointer}']
})
export class GridActionsComponent extends BaseComponent implements AgRendererComponent {
    messageBoxSubscription: Subscription;


    refresh(params: any): boolean {

        return true;
    }
    agInit(params: ICellRendererParams): void {

        this.params = params;
        this.value = this.params.value;
        this.Grid = this.params.context

        this.messageBoxSubscription = this.broadcast.observable<MessageBoxResult>('messageBoxResult').subscribe(async arg => {
            switch (arg.value.id) {
                case ('deleteAction'+this.params.rowIndex).concat(this.Grid.name ? this.Grid.name : ''):
                    if (arg.value.result === MessageBoxResultType.yes) {
                        await this.confirmDelete();
                    }
                    break;
            }
        });

    }
    afterGuiAttached(params?: IAfterGuiAttachedParams): void {


    }
    private params: ICellRendererParams;
    public value: any;
    private cancelBeforeStart: boolean = false;
    private Grid: AgGridComponent;

    getValue(): any {

        return this.params.value;
    }

    valueChange(data) {

        this.params.setValue(this.value);
    }

    viewScreen() {
        this.Grid.myscreen.screen.myForm.disable();
        this.showScreen(Enum.Mode.Display);

    }

    editScreen() {
        this.Grid.myscreen.screen.myForm.enable();
        this.showScreen(Enum.Mode.Edit);


    }

    private async showScreen(mode) {

        await this.Grid.myscreen.screen.clear()
        var node = this.Grid.options.api.getRenderedNodes().filter(o => o === this.params.node)[0];
        if (node && node.data) {
            if (this.Grid.primeryKeyField) {
                const PrimaryKeyValue = node.data[this.Grid.primeryKeyField];
                this.Grid.myscreen.mode = mode;
                this.Grid.myscreen.screen.service.addDefaultCondition(this.Grid.primeryKeyField + " = @0", [PrimaryKeyValue]);
                this.Grid.myscreen.displayLastRecord = true;
                this.Grid.index = this.params.rowIndex;
                
                var rowData = null;

                if (this.Grid.index !== -1) {

                    rowData = this.Grid.options.api.getRowNode(this.Grid.index.toString()).data;
                }


                this.Grid.actionOpen.emit(<modalArgs>{ modal: this.Grid.myscreen, rowData: rowData, cancel: false });
                this.Grid.startLoading();
                await this.Grid.myscreen.open();
                this.Grid.stopLoading();
            }

        }

    }

    async delete() {
        await this.showYesNo(('deleteAction'+this.params.rowIndex).concat(this.Grid.name ? this.Grid.name : ''), "Are you sure you want to delete!", "Warning")
    }

    async confirmDelete() {

        this.Grid.myscreen.screen.service.clearCondition();
        var node = this.Grid.options.api.getRenderedNodes().filter(o => o === this.params.node)[0];
        if (node && node.data) {
            if (this.Grid.primeryKeyField) {
                const PrimaryKeyValue = node.data[this.Grid.primeryKeyField];
                const result = await this.Grid.myscreen.screen.service.delete(PrimaryKeyValue)
                if (result.IsSuccess) {
                    this.showErrors("record successfully deleted");
                    if (this.Grid.options.api) {
                        const node = this.Grid.options.api.getRenderedNodes().filter(o => o === this.params.node)[0];

                        this.Grid.options.api.removeItems([node]);
                        if (this.Grid._data) {
                            const index = this.Grid._data.indexOf(node.data);

                            if (index !== -1) {
                                this.Grid._data.splice(index, 1);
                            }
                        }

                        this.Grid.generateIndex(0);
                    }

                }
                else {

                    this.showErrors(result.Errors)
                }
            }
        }

    }


    ngOnDestroy() {
        if (this.messageBoxSubscription) {
            this.messageBoxSubscription.unsubscribe();
        }


    }









}