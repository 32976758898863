import { Injectable } from '@angular/core';
import { AppService } from "src/app/core/service/Base.Service";

@Injectable()
export class SMApplicationService extends AppService<any> {

    constructor() {
        super('AdmApplication');
    }

    async getAdmissionApplication(entityid) {
        return this.get('AdmissionApplications', [entityid]);
    }
}