import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";

import { ICellEditorAngularComp, AgRendererComponent } from "ag-grid-angular";
import * as moment from 'moment'
import { ICellRendererParams } from "ag-grid/dist/lib/rendering/cellRenderers/iCellRenderer";
import { IAfterGuiAttachedParams } from "ag-grid/dist/lib/interfaces/iComponent";


@Component({
    selector: 'date-editor-cell',
    template: `<label class="container">  <input type="checkbox" [disabled]="disabled"   #input (change)="valueChange(data)"  [(ngModel)]="value" >  <span class="checkmark"></span> </label>`,
    styleUrls:['./checkbox.css']
})
export class GridCheckBoxComponent implements AgRendererComponent  {
    disabled: boolean = false;
    refresh(params: any): boolean {
        
       return true;
    }
    agInit(params: ICellRendererParams): void {
        
         this.params = params;
        this.disabled = !Boolean(this.params.colDef.editable === undefined ? true : this.params.colDef.editable);
        this.value = this.params.value;

    }
    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
        
        
    }
    private params: any;
    public value: any;
    private cancelBeforeStart: boolean = false;

   @ViewChild('input', {read: ViewContainerRef}) public input;


   

    getValue(): any {
        
        return  this.params.value;
    }

    valueChange(data){
        
        this.params.setValue(this.value);
    }

    

   

   

   

}