// Angular Imports
import { NgModule } from "@angular/core";

// This Module's Components
import { VisitorbookdetailComponent } from "./visitorbookdetail.component";
import { BrowserModule } from "@angular/platform-browser";
import { ReportViewerModule } from "src/app/report-viewer/report-viewer.module";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InstituteService } from "src/app/Shared/institute.service";
import { FOPurposeService } from "src/app/Shared/purpose.service";
import { ComponentModule } from "src/app/components/component.module";
import { IxsReportModule } from "src/app/components/ixs-report/ixs-report.module";

@NgModule({
  imports: [
    BrowserModule,
    ReportViewerModule,
    NgxErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentModule,
    IxsReportModule
  ],
  declarations: [VisitorbookdetailComponent],
  exports: [VisitorbookdetailComponent],
  providers: [InstituteService, FOPurposeService]
})
export class VisitorbookdetailModule {}
