// Angular Imports
import { NgModule } from "@angular/core";

// This Module's Components
import { StudentReportComponent } from "./student-report.component";
import { BrowserModule } from "@angular/platform-browser";
import { ReportViewerModule } from "src/app/report-viewer/report-viewer.module";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { CourseService } from "src/app/Reports/student-report/course.service";
import { ComponentModule } from "src/app/components/component.module";
import { IxsReportModule } from "src/app/components/ixs-report/ixs-report.module";

@NgModule({
  imports: [
    BrowserModule,
    ReportViewerModule,
    NgxErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentModule,
    IxsReportModule
  ],
  declarations: [StudentReportComponent],
  exports: [StudentReportComponent],
  providers: [CourseService],
  entryComponents: [StudentReportComponent]
})
export class StudentReportModule {}
