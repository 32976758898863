import { Injectable } from '@angular/core';
import { AppService } from "src/app/core/service/Base.Service";

@Injectable()
export class EmployeeinfohdrService extends AppService<any> {

    constructor() {
        super('Employeeinfohdr');
    }

    async GetEmployeeEntity(entityid) {
        const data = await this.get('EmployeeEntity', entityid);
        return data;
    }

    async GetEmployeeCategory(entityid, empcatid) {
        const data = await this.get('EmployeesListType', [entityid, empcatid]);
        return data;
    }
}