import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found.component";
import { StudentstatementComponent } from "src/app/Reports/Finance/Student-Statement/studentstatement.component";

const appRoutes: Routes = [
  {
    path: "**",
    redirectTo: "/home"
  }
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
