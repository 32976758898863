import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ReportViewerComponent } from "src/app/report-viewer/report-viewer.component";
import { InstituteService } from "src/app/Shared/institute.service";
import { FOPurposeService } from "src/app/Shared/purpose.service";
import { LovService } from "src/app/Shared/lov.service";

@Component({
    selector: 'callregisterdetail',
    templateUrl: 'callregisterdetail.component.html',
})
export class CallregisterdetailComponent implements OnInit {
    CallMeduim: any;
    CallType: any;
    Purpose: any;
    Entity: any;
    Course: any;
    AcademicYear: any;
    TermExam: any;
    Section: any;
    title = 'Visitor Book Detail';
    reportUrl = "";
    protected fb: FormBuilder = new FormBuilder();
    public myForm: FormGroup;
    @ViewChild('callregisterdetail') report: ReportViewerComponent;

    async ngOnInit() {
        await this.getEntity();
        await this.getPurposeType();
        await this.getCallType();
        await this.getCallMedium();
    }

    constructor(
        private lovService: LovService,
        private entityService: InstituteService,
        private purposeService: FOPurposeService,
    ) {
        this.myForm = this.fb.group({
            'entityid': ['', []],
            'code': ['', []],
            'purposetypeid': ['', []],
            'calltypeid': ['', []],
            'callmediumid': ['', []],
            'stxt': ['', []],
            'cellno': ['', []],
            'startdate': ['', []],
            'enddate': ['', []],
        });
    }

    getCallRegisterDetail() {
        let entityid;
        let code = 'null';
        let calltypeid;
        let callmediumid;
        let stxt = 'null';
        let purposetypeid;
        let cellno = 'null';
        let startdate;
        let enddate;

        if (this.myForm.controls['entityid'].value) {
            entityid = this.myForm.controls['entityid'].value;
        }

        if (this.myForm.controls['code'].value && this.myForm.controls['code'].value.trim()) {
            code = this.myForm.controls['code'].value.trim();
        }

        if (this.myForm.controls['calltypeid'].value) {
            calltypeid = this.myForm.controls['calltypeid'].value;
        }

        if (this.myForm.controls['callmediumid'].value) {
            callmediumid = this.myForm.controls['callmediumid'].value;
        }
        if (this.myForm.controls['stxt'].value && this.myForm.controls['stxt'].value.trim()) {
            stxt = this.myForm.controls['stxt'].value.trim();
        }

        if (this.myForm.controls['purposetypeid'].value) {
            purposetypeid = this.myForm.controls['purposetypeid'].value;
        }

        if (this.myForm.controls['cellno'].value && this.myForm.controls['cellno'].value.trim()) {
            cellno = this.myForm.controls['cellno'].value.trim();
        }

        if (this.myForm.controls['startdate'].value) {
            startdate = this.myForm.controls['startdate'].value;
        }

        if (this.myForm.controls['enddate'].value) {
            enddate = this.myForm.controls['enddate'].value;
        }

        this.report.invokeAction = "WebDocumentViewer/CallRegisterDetail";
        this.reportUrl = "CallRegisterDetail&api/FOCalllog/v2/CallRegister/" + entityid + "/" + purposetypeid + "/" + code + "/" + stxt + "/"  + cellno + "/" + calltypeid + "/" + callmediumid + "/" + startdate + "/" + enddate;
        this.report.getReportData();
    }

    async getEntity() {
        const dto = await this.entityService.getEntity();
        if (dto.IsSuccess) {
            this.Entity = dto.Data;
        }

        else {
            console.log(dto.Errors);
        }
    }

    async getPurposeType() {

        const dto = await this.purposeService.getPurposeType();
        if (dto.IsSuccess) {
            this.Purpose = dto.Data;
        }
        else {
            console.log(dto.Errors);
        }
    }

    async getCallType() {

        const dto = await this.lovService.getCallType();
        if (dto.IsSuccess) {
            this.CallType = dto.Data;
        }
        else {
            console.log(dto.Errors);
        }
    }

    async getCallMedium() {

        const dto = await this.lovService.getCallMedium();
        if (dto.IsSuccess) {
            this.CallMeduim = dto.Data;
        }
        else {
            console.log(dto.Errors);
        }
    }


    clearfilter() {
        this.myForm.controls['entityid'].setValue(undefined);
        this.myForm.controls['code'].setValue(undefined);
        this.myForm.controls['purposetypeid'].setValue(undefined);
        this.myForm.controls['calltypeid'].setValue(undefined);
        this.myForm.controls['callmediumid'].setValue(undefined);
        this.myForm.controls['stxt'].setValue(undefined);
        this.myForm.controls['cellno'].setValue(undefined);
        this.myForm.controls['startdate'].setValue(undefined);
        this.myForm.controls['enddate'].setValue(undefined);
    }
}