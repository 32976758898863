import { Component } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { ViewChild } from "@angular/core";
import { ReportViewerComponent } from "src/app/report-viewer/report-viewer.component";
import { OnInit } from "@angular/core";
import { OrgLegalentityService } from "src/app/Reports/OrgLegalentity/OrgLegalentity.Service";
import { LovService } from "src/app/Shared/lov.service";
import { GenEnum } from "src/app/Shared/general.enum";
import { SMStudentinfoService } from "src/app/Shared/studentprofile.service";
import { SMApplicationService } from "src/app/Shared/SMAdmApplication.service";
import { SMCourseService } from "src/app/Shared/smcourse.service";


@Component({
    selector: 'rptSMFeechallanhdr',
    templateUrl: 'sm-feechallanhdr.component.html',
})


export class SmFeechallanhdrComponent implements OnInit {
    Section: any;
    Course: any;
    Application: any;
    StudentList: any;
    FeeFor: any;
    SMFeechallanhdr: any;
    Legalentity: any;

    async ngOnInit() {
        await this.getEntity();
    }

    title = 'Fee Challan';
    reportUrl = "";
    protected fb: FormBuilder = new FormBuilder();
    public myForm: FormGroup;


    constructor(
        private orgLegalentityService: OrgLegalentityService,
        private lovService: LovService,
        private studentService: SMStudentinfoService,
        private applicationService: SMApplicationService,
        private courseService: SMCourseService
    ) {
        this.myForm = this.fb.group({
            'entityid': ['', []],
            'appid': ['', []],
            'code': ['', []],
            'studentid': ['', []],
            'courseid': ['', []],
            'sectionid': ['', []],
            'duedate': ['', []],
        });
    }

    @ViewChild('SMFeechallanhdr') report: ReportViewerComponent

    getStudentChallan() {
        let entityid = null;
        let appid = null;
        let code = 'null';
        let studentid = null;
        let courseid = null;
        let sectionid = null;
        let duedate = null;

        if (this.myForm.controls['entityid'].value) {
            entityid = this.myForm.controls['entityid'].value;
        }

        if (this.myForm.controls['code'].value && this.myForm.controls['code'].value.trim()) {
            code = this.myForm.controls['code'].value.trim();
        }

        if (this.myForm.controls['appid'].value) {
            appid = this.myForm.controls['appid'].value;
        }

        if (this.myForm.controls['studentid'].value) {
            studentid = this.myForm.controls['studentid'].value;
        }

        if (this.myForm.controls['courseid'].value) {
            courseid = this.myForm.controls['courseid'].value;
        }

        if (this.myForm.controls['sectionid'].value) {
            sectionid = this.myForm.controls['sectionid'].value;
        }

        if (this.myForm.controls['duedate'].value) {
            duedate = this.myForm.controls['duedate'].value;
        }

        this.report.invokeAction = "WebDocumentViewer/SMFeechallanhdr";
        this.reportUrl = "SMFeechallanhdr&api/Feechallanhdr/v2/StudentChallanReport/" + entityid + "/" + appid + "/" + code + "/" + studentid + "/" + courseid + "/" + sectionid + "/" + duedate;
        this.report.getReportData();
    }

    async getEntity() {
        const result = await this.orgLegalentityService.getAll();
        if (result.IsSuccess) {
            this.Legalentity = result.Data;
        }
    }

    async getFeeFor() {
        const dto = await this.lovService.getFeeFor();
        if (dto.IsSuccess) {
            this.FeeFor = dto.Data;
        } else {
            console.log(dto.Errors);
        }

    }

    async getStudent(entityid) {
        if (entityid) {
            const dto = await this.studentService.getStudent(entityid)
            if (dto.IsSuccess) {
                this.StudentList = dto.Data;
            } else {
                console.log(dto.Errors);
            }
        }
    }

    async getApplication(entityid) {
        if (entityid) {
            const dto = await this.applicationService.getAdmissionApplication(entityid);
            if (dto.IsSuccess) {
                this.Application = dto.Data;
            } else {
                console.log(dto.Errors);
            }
        }
    }

    async getEntityCourse(entityid) {
        const dto = await this.courseService.getEntityCourse(entityid);
        if (dto.IsSuccess) {
            this.Course = dto.Data;
        } else {
            console.log(dto.Errors);
        }
    }

    async getSection(courseid) {
        const result = await this.courseService.getsectionAgainstCourse(courseid);
        if (result.IsSuccess) {
            this.Section = result.Data;
        }
    }
    async getOnEntityChanged() {
        let entityid = Number(this.myForm.controls['entityid'].value);
        if (entityid) {
            this.StudentList = [];
            this.Application = [];
            this.Course = [];
            this.Section = [];

            await this.getStudent(entityid);
            await this.getApplication(entityid);
            await this.getEntityCourse(entityid);
            await this.getSection(entityid);
        }
        else {
            this.StudentList = [];
            this.Application = [];
            this.Course = [];
            this.Section = [];
        }
    }

    clearfilter() {
        this.myForm.controls['entityid'].setValue(null);
        this.myForm.controls['appid'].setValue(null);
        this.myForm.controls['code'].setValue(null);
        this.myForm.controls['studentid'].setValue(null);
        this.myForm.controls['courseid'].setValue(null);
        this.myForm.controls['sectionid'].setValue(null);
        this.myForm.controls['duedate'].setValue(null);

        this.StudentList = [];
        this.Application = [];
        this.Course = [];
        this.Section = [];
    }
}
