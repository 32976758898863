import { Injectable } from '@angular/core';
import { AppService } from '../../core/service/Base.Service';
import { DataTransferObject } from '../../core/entities/DataTransferObject';

@Injectable()
export class CityService extends AppService<any> {

    constructor() {
        super('City');
    }

    async getCity(citycode: any) {
        const result = await this.get('City', citycode);
        return result;
    }

    async getCityByID(countryid: any) {
        const result = await this.get('CountryCity', countryid);
        return result;
    }

    async GetCountryStateCity(countryid, stateid) {
        const result = await this.get('CountryStateCity', [countryid, stateid]);
        return result;
    }
}
