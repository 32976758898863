import {
  Component,
  OnInit,
  AfterViewInit,
  Pipe,
  PipeTransform
} from "@angular/core";
import { List } from "linqts";
import { SmConstants } from "../sm/sm.constants";
import { AppConstants } from "../../app.constant";
import { MenusService } from "../../core/service/menus.service";
import { Broadcast } from "../../core/events/broadcast";
import { StudentReportComponent } from "src/app/Reports/student-report/student-report.component";
import { extend } from "webdriver-js-extender";
import { LocalStorage } from '../../core/helper/localStorage';
import { BaseComponent } from "src/app/core/component/Base.Component";
import { GenEnum } from "src/app/Shared/general.enum";
import { generate } from "rxjs";
import { RouterModule, NavigationExtras, ActivatedRoute } from "@angular/router";

@Component({
  selector: "sidebar-left",
  templateUrl: "../shell-templates/sidebar-left.component.html"
})
export class SidebarLeftComponent extends BaseComponent {
  tree: Array<any>;

  title = "Navigation Menu";
  menuid: any;
  returnmenuid: number;
  url: string;
  entityName: String;
  constructor(private menusService: MenusService,private route: ActivatedRoute) {
    super();
    this.broadcast = AppConstants.injector.get(Broadcast);
    this.localStorage = AppConstants.injector.get(LocalStorage);
    this.url = this.localStorage.get("EntityLogo");

  }

  async ngOnInit() {
    await this.getMenu();

    this.route.queryParams.subscribe(params => {

      this.returnmenuid = Number(params.menuid);

    });
    this.entityName = this.localStorage.get("ClientStxt");

  }

  ShortName = this.localStorage.get('ShortName');
  EntityLogo = this.localStorage.get('EntityLogo');

  async getMenu() {
    const dto = await this.menusService.getMenu(
      AppConstants.urls.app.apptype.Report
    );
    if (dto.IsSuccess) {
      this.tree = dto.Data;
      this.addRoutes(this.tree);
    } else {
      console.log(dto.Errors);
    }
  }

  private navigateRoute(item: any) {

    if (item.menucatid === GenEnum.MenuCategory.Screens) {
      this.navigate("/" + item.controllerId);
    } else if (item.menucatid === GenEnum.MenuCategory.Reports) {

      if (item.utreporthdr && item.utreporthdr.code) {

        this.navigate("/" + item.utreporthdr ? item.utreporthdr.code : "");
      }
    }

  }

  addRoutes(data: any[]) {
    var config = this.router.config;
    var smComponent;
    if (config) {
      smComponent = config.filter(o => o.path === "")[0];
    }
    data.forEach(e => {
      if (e.children) {
        e.children.forEach(schild => {
          schild.children.forEach(c => {
            if (smComponent && smComponent.children) {
              smComponent.children.push({
                path:
                  c.menucatid === GenEnum.MenuCategory.Screens
                    ? c.controllerId
                    : c.utreporthdr
                      ? c.utreporthdr.code
                      : "",
                component: StudentReportComponent
              });
            }
          });
        });
      }
    });
  }

  async getMenuScreenNavigate(event) {

    if (event.currentTarget.id) {

      this.menuid = event.currentTarget.id;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          menuid: this.menuid,
          menucollection: JSON.stringify(this.tree)
        },
        replaceUrl: false
      };
      this.router.navigate(['menuscreen'], navigationExtras);
    }
  }


}

@Pipe({
  name: "filter"
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.label.toLowerCase().includes(searchText);
    });
  }
}
