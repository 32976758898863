import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { BsModalComponent } from "ng2-bs3-modal";
import { LookupConfiguration, LookupResult } from "./lookup.config";
import { BaseComponent } from "../../core/component/Base.Component";
import { AppConstants } from "../../app.constant";

@Component({
  selector: "ixs-lookup",
  templateUrl: "lookup.component.html"
})
export class LookupComponent extends BaseComponent {
  @ViewChild("modal")
  public modal: BsModalComponent;

  @Input()
  public Config: LookupConfiguration[];
  @Input()
  public Data: any[];
  @Input()
  public Caption: String;
  @Input()
  public LookupId: String;

  private dateFormat: string = "";
  private timeFormat: string = "";

  constructor() {
    super();

    this.dateFormat = AppConstants.settings.dateFormat;
    this.timeFormat = AppConstants.settings.timeFormat;
  }

  async ngOnInit() {
    this.modal.backdrop = "static";
  }

  open() {
    this.modal.open("lg");
  }

  select(event) {
    this.broadcast.publish<LookupResult>("lookup", <LookupResult>{
      lookupId: this.LookupId,
      data: event.data
    });

    this.modal.close();
  }
}
