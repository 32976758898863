import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { BsModalComponent } from "ng2-bs3-modal";
import { BaseComponent } from "../../core/component/Base.Component";
import {
  MessageBoxSettings,
  MessageBoxResult,
  MessageBoxResultType
} from "./messagebox.config";

@Component({
  selector: "ixs-messagebox",
  templateUrl: "messagebox.component.html"
})
export class MessageBoxComponent extends BaseComponent {
  public setting: MessageBoxSettings = <MessageBoxSettings>{};

  @ViewChild("modal")
  public modal: BsModalComponent;

  constructor() {
    super();
  }

  async ngOnInit() {
    this.modal.backdrop = "static";
  }

  open(setting: MessageBoxSettings) {
    this.setting = setting;

    this.modal.open("lg");
  }

  select(event) {
    this.modal.close();
  }

  async ok() {
    await this.publish(MessageBoxResultType.ok);
  }

  async yes() {
    await this.publish(MessageBoxResultType.yes);
  }

  async no() {
    await this.publish(MessageBoxResultType.no);
  }

  async cancel() {
    await this.publish(MessageBoxResultType.cancel);
  }

  private async publish(result: MessageBoxResultType) {
    await this.broadcast.publish<MessageBoxResult>("messageBoxResult", <
      MessageBoxResult
    >{
      id: this.setting.id,
      result
    });

    this.modal.close();
  }
}
