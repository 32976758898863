import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ReportViewerComponent } from './report-viewer.component';

@NgModule({
  declarations: [
       ReportViewerComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  exports:[ReportViewerComponent]
})
export class ReportViewerModule { }
