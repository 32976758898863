import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";

import { UserClientsComponent } from "./userclients.component";

import { UserClientsRoutingModule } from "./userclient-routing.module";
import { InstituteService } from "../shell/forms/organizational-setup/setup/institute/institute.service";
import { SystemPolicyService } from "../shell/forms/organizational-setup/setup/systempolicy/systempolicy.service";
import { ImageService } from "../shell/shared/image.service";
import { ComponentModule } from "../components/component.module";

@NgModule({
  imports: [CommonModule, FormsModule, UserClientsRoutingModule,ComponentModule],
  declarations: [UserClientsComponent],
  providers: [InstituteService, SystemPolicyService, ImageService]
})
export class UserClientsModule {}
