import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  ContentChild,
  ContentChildren,
  AfterContentInit,
  HostListener,
  Renderer,
  AfterViewInit
} from "@angular/core";
import { BsModalComponent } from "ng2-bs3-modal";
import { BaseComponent } from "../../core/component/Base.Component";
import { Router } from "@angular/router";
import { AppConstants } from "../../app.constant";
import { NavComponent } from "../../core/component/Nav.Component";
import { NavModalComponent } from "../../core/component/NavModal.Component";
import { LookupResult } from "../lookup/lookup.config";
import { DataTransferObject } from "../../core/entities/DataTransferObject";
import { Subscription } from "rxjs/Subscription";
import {
  MessageBoxSettings,
  MessageBoxResult,
  MessageBoxResultType
} from "../messagebox/messagebox.config";
import { MessageBoxComponent } from "../messagebox/messagebox.component";
import { Enum } from "../config";

@Component({
  selector: "ixs-modal",
  templateUrl: "ixsmodal.component.html",
  styles: [".btn-xs1{width:88px !important;}"]
})
export class IXSBsModalComponent extends BaseComponent
  implements AfterViewInit {
  ngAfterViewInit(): void {
    //this.zindex += Number(this.lvl);
  }

  private lookupSubscription: Subscription;

  @ViewChild("ixsmodal")
  public modal: BsModalComponent;

  //@ContentChild (NavComponent) template:NavComponent<any>

  @ContentChild("ngContent") screen: NavModalComponent<any>;

  @Input()
  public Caption: String;

  @Input()
  screenCode: string;

  @Input()
  displayLastRecord: boolean = false;

  @Input()
  mode: number = Enum.Mode.Default;

  _lvl: number = 1;
  @Input()
  set lvl(value: number) {
    this._lvl = value;
    this.zindex += this._lvl;
  }

  private zindex: number = 1049;

  constructor(private element: ElementRef, private renderer: Renderer) {
    super();
  }

  //for the purpose of fire event on lister select event
  public triggeronOpen() {
    let event = new CustomEvent("onOpen", { detail: this, bubbles: true });
    this.renderer.invokeElementMethod(
      this.element.nativeElement,
      "dispatchEvent",
      [event]
    );
  }

  public triggeronClose() {
    let event = new CustomEvent("onClose", { detail: this, bubbles: true });
    this.renderer.invokeElementMethod(
      this.element.nativeElement,
      "dispatchEvent",
      [event]
    );
  }

  async ngOnInit() {
    this.modal.backdrop = "static";
  }

  async open() {
    this.lookupSubscription = this.broadcast
      .observable<LookupResult>("lookup")
      .subscribe(arg => {
        switch (arg.value.lookupId) {
          case this.screenCode + "lister":
            this.screen.setDto(<DataTransferObject<any>>{
              Data: arg.value.data,
              KeyValue: arg.value.data.PrimaryKeyValue
            });
            break;
        }
      });

    this.Caption = this.Caption ? this.Caption : this.screen.title;
    await this.screen.initialize();
    await this.modal.open("lg");
    this.triggeronOpen();

    if (this.displayLastRecord) {
      await this.screen.moveLast();
    }

    // await this.course.moveLast();
  }

  showModalLister() {
    this.screen.showLister(this.screenCode + "lister");
  }

  async deleteModalData() {
    await this.screen.confirmDelete();
  }

  close() {
    if (this.lookupSubscription) {
      this.lookupSubscription.unsubscribe();
    }

    if (this.screen && this.screen.service) {
      this.screen.service.clearCondition();
    }

    this.triggeronClose();
  }

  async saveAndClose() {
    const issuccess = await this.screen.save();

    if (issuccess) {
      this.modal.close();
      this.close();
    }
  }

  private async innerClose() {
    this.screen.service.clearCondition();
    await this.screen.clear();
  }
}
