import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular/main";
import { moodConfig } from "../../../config";

@Component({
    selector: 'mood-cell',
    template: ` <i [ngClass]="icon ? icon : '' "><a>{{innervalue ? '    '+ innervalue : ''}}</a></i> `,
    styles:["a{font-Family:'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;color:black;font-weight:400}"]
})
export class MoodRendererComponent implements ICellRendererAngularComp {
    private params: any;
    public innervalue: string;
    private data: [moodConfig];
    private valueMember;
    private displayMember;
    private column: any;
    private icon;
    private isMenu: boolean;


    agInit(params: any) {
        
        this.params = params;
        this.column = params.column;
        this.loadData();
        this.setvalue(params.value);
    }

    refresh(params: any): boolean {
        this.params = params;
        this.column = params.column;
        this.loadData();
        if (params.value) {
            this.setvalue(params.value);
        }
        return true;
    }

    private setvalue(value) {
        
        if (this.data && value) {
            const fil = this.data.filter(o => String(o[this.valueMember]) === String(value))[0];
            if (!this.isMenu) {
                this.innervalue = fil[this.displayMember];
                this.icon = fil['moodIcon'];
            }
            else{
                this.innervalue = '';
                this.icon = 'fa fa-tasks ';
            }

        }
        else {
            this.innervalue = '';
            this.icon = this.isMenu ? 'fa fa-tasks ' : '';
        }
    };

    private loadData() {

        this.valueMember = this.column.colDef.dataValueField;
        this.displayMember = this.column.colDef.dataDisplayField;
        this.isMenu = Boolean(this.column.colDef.isMenu);
        this.data = this.column.colDef.dropdownData;

    }
}