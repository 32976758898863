import { LocalStorage } from "./../../../../../core/helper/localStorage";
import { Injectable } from "@angular/core";
import { AppService } from "../../../../../core/service/Base.Service";
import { DataTransferObject } from "../../../../../core/entities/DataTransferObject";
import { Institute } from "./institute";

@Injectable()
export class InstituteService extends AppService<any> {
  constructor() {
    super("Legalentity");
  }

  async getInstitution() {
    const result = await this.get("");

    return result;
  }

  async postEntitiesAgainstClients(clients) {
    const data = <DataTransferObject<any>>{
      Data: clients
    };

    const result = await this.post("EntitiesAgainstClients", data);
    return result;
  }

  async getentityList(entityid) {
    const data = this.get("EntityList", [entityid]);
    return data;
  }
}
