import { ViewContainerRef, AfterViewInit, Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular/main';
import { Broadcast } from '../../../../core/events/broadcast';
import { AgRendererComponent } from "ag-grid-angular/dist/interfaces";
import { IAfterGuiAttachedParams } from "ag-grid/dist/lib/interfaces/iComponent";
import { IXSDropdownComponent } from "../../../dropdown/dropdown.component";
import { ICellRendererParams } from "ag-grid/dist/lib/rendering/cellRenderers/iCellRenderer";
import { MapConfiguration } from "../../../config";
import { RowNode } from "ag-grid/dist/lib/entities/rowNode";




@Component({
    selector: 'select-cell',
    template: `
<ixs-dropdown   #select (change)="valueChange()" [(ngModel)]="value"  >  </ixs-dropdown>`,
    styleUrls: ['selectStyle.css']
})

export class GridSelectComponent implements AgRendererComponent {

    @ViewChild('select') dropdown: any

    refresh(params: any): boolean {

        return true;
    }
    afterGuiAttached(params?: IAfterGuiAttachedParams): void {


    }
    private params: any;

    private column: any;

    public value: any;




    constructor() {

    }

    private async loadData() {

        this.dropdown.fieldName = this.column.colId + this.params.rowIndex;
        this.dropdown.showCaption = false;
        this.dropdown.valueMember = this.column.colDef.dataValueField;
        this.dropdown.displayMember = this.column.colDef.dataDisplayField;
        this.dropdown.caption = this.column.colDef.headerName;
        this.dropdown.config = this.column.colDef.config;
        this.dropdown.data = this.column.colDef.dropdownData;
        this.dropdown.disabled = !Boolean(this.params.colDef.editable === undefined ? true :this.params.colDef.editable);

    }


    async agInit(params: ICellRendererParams) {



        
        const element:HTMLElement = this.dropdown.element.nativeElement.children[0].children[0];
        if (element) {
            element.classList.remove('form-control');
            element.style.width = "100%";
            element.style.border = "none";
            element.style.backgroundColor = "transparent"
            

            
        }
        const span:HTMLElement = this.dropdown.element.nativeElement.children[0].children[1];
        if (span) {
                  
                    span.style.backgroundColor = "transparent"
                    span.style.borderStyle="none"
        }
        
        this.params = params;
        this.value = this.params.value;
        this.column = this.params.column;
        await this.loadData();
        this.mapFields();
        

    }

    getValue(): any {

        return this.params.value;
    }

    valueChange() {

        this.params.setValue(this.value);
        this.mapFields();
    }

    mapFields() {
        if (this.params && this.params.node && this.column.colDef.mapFields && this.dropdown.data && this.value) {
            this.column.colDef.mapFields.forEach((element: MapConfiguration) => {
                const data = this.dropdown.data.filter(o => o[this.dropdown.valueMember] === this.value)[0];
                if (data) {
                    const val = data[element.mapField];
                    if (val) {
                        this.params.node.setDataValue(element.field, val);
                    }
                }

            });
        }
    }




}
