import { NavComponent } from "./Nav.Component";
import { BaseService } from "../service/Base.Service";

export abstract class NavModalComponent<T> extends NavComponent<any> {
  constructor(private baseservice: BaseService<T>) {
    super(baseservice);

    // this.moveLast();
  }

  async initialize() {
    await this.modalInitialize();
  }
}
