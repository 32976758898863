import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { LocalStorage } from './localStorage';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router, private localStorage: LocalStorage) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkToken();
    }

    canActivateChild() {
        return this.checkToken();
    }

    private checkToken() {
        if (this.localStorage.get('token') && this.localStorage.get('selectedEntity')) {
            return true;
        }

        this.router.navigate(['login']);
        return false;
    }
}