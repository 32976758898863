import {
  Component,
  OnInit,
  ReflectiveInjector,
  Injector,
  ViewChildren,
  QueryList,
  ElementRef,
  Input,
  AfterViewInit,
  forwardRef
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { ToastsManager } from "ng6-toastr";
import * as moment from "moment";

import { ToolbarEvent } from "../../core/events/toolbar.event";
import { BaseService } from "../service/Base.Service";
import { DataTransferObject } from "../entities/DataTransferObject";
import { AppConstants } from "../../app.constant";
import { LocalStorage } from "../helper/localStorage";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl
} from "@angular/forms";

import { BaseComponent } from "./Base.Component";
import { Broadcast } from "../events/broadcast";
import { Subscription } from "rxjs/Subscription";
import { LookupResult } from "../../components/lookup/lookup.config";
import {
  MessageBoxResult,
  MessageBoxResultType
} from "../../components/messagebox/messagebox.config";
import { IXSBsModalComponent } from "../../components/ixsmodal/ixsmodal.component";
import { IxsloaderComponent } from "../../components/ixsloader/ixsloader.component";

export abstract class NavComponent<T> extends BaseComponent
  implements AfterViewInit {
  ngAfterViewInit(): void {
    this.toolbarEvent = AppConstants.injector.get(ToolbarEvent);

    if (!this.Modal) {
      this.toolbarSubscription = this.toolbarEvent
        .click()
        .subscribe(async arg => {
          switch (arg.key) {
            case "first":
              await this.moveFirst();
              break;
            case "last":
              await this.moveLast();
              break;
            case "next":
              await this.moveNext();
              break;
            case "previous":
              await this.movePrevious();
              break;
            case "save":
              await this.save();
              break;
            case "delete":
              await this.delete();
              break;
            case "clear":
              await this.clear();
              break;
            case "search":
              await this.showLister("lister");
              break;
          }
        });

      this.lookupSubscription = this.broadcast
        .observable<LookupResult>("lookup")
        .subscribe(arg => {
          switch (arg.value.lookupId) {
            case "lister":
              this.setDto(<DataTransferObject<T>>{
                Data: arg.value.data,
                KeyValue: arg.value.data.PrimaryKeyValue
              });
              break;
          }
        });

      this.messageBoxSubscription = this.broadcast
        .observable<MessageBoxResult>("messageBoxResult")
        .subscribe(async arg => {
          switch (arg.value.id) {
            case "delete":
              if (arg.value.result === MessageBoxResultType.yes) {
                await this.confirmDelete();
              }
              break;
          }
        });
      this.broadcast.publish<string>("title", this.title);
      this.modalInitialize();
    }
  }
  private _model: T = <T>{};
  private _dto: DataTransferObject<T> = <DataTransferObject<T>>{};
  private currentAddOn: any;
  private key: any;
  private toolbarEvent: ToolbarEvent;
  private toolbarSubscription: Subscription;
  private lookupSubscription: Subscription;
  private messageBoxSubscription: Subscription;

  protected fb: FormBuilder;
  public title: string;
  public set setTitle(value: string) {
    this.title = value;
    if (this.title) this.broadcast.publish<string>("title", this.title);
  }
  private userrole;
  public myForm: FormGroup;
  @Input()
  Modal: boolean = false;
  @ViewChildren(forwardRef(() => IXSBsModalComponent)) myscreens: QueryList<
    IXSBsModalComponent
  >;
  constructor(public service: BaseService<T>) {
    super();

    this.fb = AppConstants.injector.get(FormBuilder);
    this.initilizeForm();

    this.broadcast.publish<FormGroup>("formgroup", this.myForm);

    this.hideAddToolbar(false);
    this.hideDeleteToolbar(false);
    // this.showToolbar(true);

    this.userrole = JSON.parse(localStorage.getItem("userrole"));
  }

  initilizeForm() { }

  //  Properties
  get model(): T {
    return this._model;
  }

  set model(value: T) {
    this._model = value || <T>{};
  }

  get dto(): DataTransferObject<T> {
    return this._dto;
  }

  async ngOnInit() { }

  ngOnDestroy() {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }

    if (this.lookupSubscription) {
      this.lookupSubscription.unsubscribe();
    }

    if (this.messageBoxSubscription) {
      this.messageBoxSubscription.unsubscribe();
    }

    if (this.service !== undefined) this.service.clearCondition();
  }

  // Service Method
  async moveNext() {
    if (this.checkUserRole(this.service.controller, "view")) {
      this.startLoading();
      const dto = await this.service.getNext(this.key);
      this.setDto(dto);
      this.stopLoading();
    }
  }

  async movePrevious() {
    if (this.checkUserRole(this.service.controller, "view")) {
      this.startLoading();
      const dto = await this.service.getPrevious(this.key);
      this.setDto(dto);
      this.stopLoading();
    }
  }

  async moveLast() {
    if (this.checkUserRole(this.service.controller, "view")) {
      this.startLoading();
      const dto = await this.service.getLast();
      this.setDto(dto);
      this.stopLoading();
    }
  }

  async moveFirst() {
    if (this.checkUserRole(this.service.controller, "view")) {
      this.startLoading();
      const dto = await this.service.getFirst();
      this.setDto(dto);
      this.stopLoading();
    }
  }

  async clear() {
    this.startLoading();
    await this.beforeClear();
    Object.keys(this.myForm.controls).map(controlName => {
      this.myForm.get(controlName).markAsUntouched({ onlySelf: true });
      this.myForm.get(controlName).markAsPristine({ onlySelf: true });
    });

    this.model = <T>{};
    this.currentAddOn = {};
    this.key = undefined;

    this.setFormValues();

    await this.afterClear();
    this.stopLoading();
  }

  async save(): Promise<boolean> {
    let rtn: boolean = false;
    if (
      this.checkUserRole(
        this.service.controller,
        this.key ? "update" : "create"
      )
    ) {
      this.startLoading();

      let isDirty: boolean;
      let ctrl;

      Object.keys(this.myForm.controls).map(controlName => {
        ctrl = this.myForm.get(controlName);
        ctrl.markAsDirty({ onlySelf: true });
        ctrl.markAsTouched({ onlySelf: true });

        if (!isDirty) {
          isDirty = ctrl.errors !== null;
        }
      });

      if (!isDirty) {
        await this.beforeSave();
        const dto = this.getDto();

        dto.ReturnObject = true;
        const result = await this.service.save(dto);

        if (result.IsSuccess) {
          rtn = true;
          this.showSuccess("Record saved successfully");
          this.setDto(result);
          await this.afterSave();
        } else {
          this.showErrors(result.Errors);

          result.Errors.forEach(error => {
            if (error && error.Fields) {
              error.Fields.forEach(controlName => {
                var ctrl: AbstractControl = this.myForm.get(controlName);
                if (ctrl) {
                  ctrl.setErrors({ incorrect: "My Error" });
                }
              });
            }
          });
        }
      }

      this.stopLoading();
    }
    return rtn;
  }

  async delete() {
    if (this.checkUserRole(this.service.controller, "delete")) {
      await this.showYesNo(
        "delete",
        "Are you sure you want to delete record?",
        "Warning"
      );
    }
  }

  async confirmDelete() {
    this.startLoading();
    const result = await this.service.delete(this.key);
    this.stopLoading();
    if (result !== undefined && result.IsSuccess) {
      this.setDto(result);
      this.showSuccess("Record deleted successfully");
    } else if (result !== undefined || !result.IsSuccess) {
      this.showErrors(result.Errors);
    }
  }

  // Protected Methods

  protected async beforeDisplay() { }

  protected async afterDisplay() { }

  protected async beforeClear() { }

  protected async afterClear() { }

  protected async beforeSave() { }

  protected async afterSave() { }

  async showLister(lookupId: string) {
    if (this.checkUserRole(this.service.controller, "view")) {
      if (this.lookupConfigs.lister) {
        this.startLoading();
        const dto = await this.service.getAll();
        this.stopLoading();
        if (dto.IsSuccess) {
          this.showLookup(
            this.lookupConfigs.lister,
            dto.Data,
            lookupId,
            this.title + " - Search..."
          );
        } else {
          this.showErrors(dto.Errors);
        }
      }
    }
  }

  public setDto(dto: DataTransferObject<T>) {
    this._dto = dto;
    this.beforeDisplay();

    this.model = dto.Data;

    if (dto && dto.Errors) this.showErrors(dto.Errors[0].Description);

    console.log(dto);
    console.log(this.model);

    this.setFormValues();

    this.currentAddOn = dto.DataAddon;
    this.key = dto.KeyValue;

    this.afterDisplay();
  }

  private getDto() {
    Object.keys(this.myForm.value).forEach(name => {
      this.model[name] = this.myForm.value[name];
    });

    const result = <DataTransferObject<T>>{
      Data: this.model,
      DataAddon: this.currentAddOn,
      KeyValue: this.key
    };

    return result;
  }

  private setFormValues() {
    Object.keys(this.myForm.value).forEach(name => {
      if (this.model && this.model[name]) {
        const value = this.model[name];
        const ctrl = this.myForm.controls[name];

        if (name === "starttime") {
          // ctrl.setValue(moment(value).format('HH:mm'));
          ctrl.setValue(value);
        } else {
          ctrl.setValue(value);
        }
      } else {
        this.myForm.controls[name].setValue(undefined);
      }
    });
  }

  async openScreen(screenCode: string) {
    this.startLoading();
    if (screenCode) {
      const data = this.myscreens.filter(o => o.screenCode === screenCode)[0];
      if (data) {
        await data.open();
      } else {
        console.log("Screen not found against screen code");
      }
    } else {
      console.log("Please give Screen Code");
    }
    this.stopLoading();
  }

  protected async modalInitialize() { }

  startLoading() {
    if (this.toolbarEvent) this.toolbarEvent.boardcast("start");
  }

  stopLoading() {
    if (this.toolbarEvent) this.toolbarEvent.boardcast("stop");
  }

  checkUserRole(controller: string, rightId: string): boolean {
    var chk: boolean;

    if (this.userrole) {
      const right = this.userrole.utrolerights.filter(
        e => e.controllerId === controller && e.rightId === rightId
      )[0];
      if (right) chk = true;
    }

    if (!chk) this.showErrors("Access denied.");

    return chk;
  }

  public setTitleNIcon(value: string, titleIcon: string) {
    this.title = value;
    this.broadcast.publish<string>("title", this.title);
    this.broadcast.publish<string>("titleIcon", titleIcon);
  }
}
