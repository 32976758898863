import { Injectable } from '@angular/core'
import { AppService } from "src/app/core/service/Base.Service";
import { AppConstants } from "src/app/app.constant";

@Injectable()
export class SMAcademicYearService extends AppService<any>{
    constructor() {
        super('SMAcademicYear');
    }

    async getEntityAcademicyear(entityid ) {
        if (entityid > 0) {
            const result = await this.get('EntityAcademicyear', [entityid]);
            return result;
        }
    }
}

















