import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserClientsComponent }    from '../userclients/userclients.component';


const userclients: Routes = [
  {
    path: 'userclients',
    component: UserClientsComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(userclients)
  ],
  exports: [
    RouterModule
  ]
})
export class UserClientsRoutingModule { }
