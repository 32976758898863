import { NgModule } from "@angular/core";
import { RouterModule, Routes, Router } from "@angular/router";
import { AuthGuard } from "../../core/helper/auth.guard";
import { SmComponent } from "./sm.component";
import { HomeComponent } from "../forms/home/home.component";
import { InstituteComponent } from "../forms/organizational-setup/setup/institute/institute.component";
import { ExamscheduleComponent } from "src/app/Reports/Academic/Exam/ExamSchedule/examschedule.component";
import { AdmissionenquirydetailComponent } from "src/app/Reports/Front Office/Admission-Enquiry-Detail/admissionenquirydetail.component";
import { AdmissionenquiryComponent } from "src/app/Reports/Front Office/Admission-Enquiry/admissionenquiry.component";
import { VisitorbookdetailComponent } from "src/app/Reports/Front Office/Visitor-Book-Detail/visitorbookdetail.component";
import { SmFeechallanhdrComponent } from "src/app/Reports/sm-feechallanhdr/sm-feechallanhdr.component";
import { CallregisterdetailComponent } from "src/app/Reports/Front Office/Call-Register-Detail/callregisterdetail.component";
import { PostalregisterdetailComponent } from "src/app/Reports/Front Office/Postal-Register-Detail/postalregisterdetail.component";
import { MenuScreenComponent } from "../forms/menu-screen/menu-screen.component";

const smRoutes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: SmComponent,
    children: [
      {
        component: HomeComponent,
        path: "home"
      },
      {
        component: InstituteComponent,
        path: "Legalentity"
      },
      {
        path: "examschedule",
        component: ExamscheduleComponent
      },
      {
        path: "admissionenquirydetail",
        component: AdmissionenquirydetailComponent
      },
      {
        path: "admissionenquiry",
        component: AdmissionenquiryComponent
      },
      {
        path: "visitorbookdetail",
        component: VisitorbookdetailComponent
      },
      {
        path: "rptSMFeechallanhdr",
        component: SmFeechallanhdrComponent
      },
      {
        path: "callregisterdetail",
        component: CallregisterdetailComponent
      },
      {
        path: "postalregisterdetail",
        component: PostalregisterdetailComponent
      },
      {
        path: "menuscreen",
        component: MenuScreenComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(smRoutes)],
  exports: [RouterModule]
})
export class SmRoutingModule {
  subscription: any;

  constructor() {}
}
