import { MenuScreenService } from './menu-screen.service';
// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { MenuScreenComponent } from './menu-screen.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { ComponentModule } from 'src/app/components/component.module';
import { BsModalModule } from 'ng2-bs3-modal';
import { SidebarLeftComponent } from '../../shell-components/sidebar-left.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TextMaskModule,
        NgxErrorsModule,
        ComponentModule,
        BsModalModule
    ],
    declarations: [
        MenuScreenComponent,
    ],
    exports: [
        MenuScreenComponent,
    ],
    providers: [SidebarLeftComponent,MenuScreenService]
})
export class MenuScreenModule {

}
