import {
  Component,
  Input,
  ViewChild,
  forwardRef,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer,
  EventEmitter,
  Output,
  ContentChild,
  QueryList,
  ViewChildren
} from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  SelectControlValueAccessor
} from "@angular/forms";
import { SELECT_VALUE_ACCESSOR } from "@angular/forms/src/directives/select_control_value_accessor";

@Component({
  selector: "ixs-checkbox",
  templateUrl: "ixscheckbox.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IXSCheckBoxComponent),
      multi: true
    }
  ]
})
export class IXSCheckBoxComponent implements ControlValueAccessor {
  constructor() {}

  // //for the purpose of fire event on lister select event
  // public triggerChanged() {
  //     let event = new CustomEvent('change', { bubbles: true });
  //     this.renderer.invokeElementMethod(this.element.nativeElement, 'dispatchEvent', [event]);
  // }

  private innerValue: any = "";

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @Input()
  caption: string;

  @Input()
  disabled: boolean = false;

  @Input()
  fieldName: string;

  @Input()
  position: number = 1;
}
