import { Component, OnInit, AfterViewInit } from '@angular/core';

declare var jQuery: any;

@Component({
	
	selector: "sidebar-right",
	templateUrl: '../shell-templates/sidebar-right.component.html'
})

export class SidebarRightComponent {
  title = "Menu";

	constructor(
	) { }

	ngOnInit(): void {
	}

	ngAfterViewInit() {
    // if (jQuery.AdminLTE && jQuery.AdminLTE.controlSidebar) {
    //   jQuery.AdminLTE.controlSidebar.activate();
    // }
  }
}
