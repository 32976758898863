import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportViewerComponent } from "src/app/report-viewer/report-viewer.component";
import { InstituteService } from "src/app/Shared/institute.service";
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { LovService } from "src/app/Shared/lov.service";
import { SMCourseService } from "src/app/Shared/smcourse.service";
import { EmployeeinfohdrService } from "src/app/Shared/employee.service";

@Component({
    selector: 'admissionenquirydetail',
    templateUrl: 'admissionenquirydetail.component.html',
})
export class AdmissionenquirydetailComponent implements OnInit {
    CourseSection: any;
    Employee: any;
    Course: any;
    Response: any;
    Entity: any;
    reportUrl: string;
    title = 'Admission Enquiry Detail';

    protected fb: FormBuilder = new FormBuilder();
    public myForm: FormGroup;
    @ViewChild('admissionenquirydetail') report: ReportViewerComponent;

    async ngOnInit() {
        await this.getEntity();
        await this.getResponse();
    }

    constructor(
        private entityService: InstituteService,
        private lovService: LovService,
        private courseservice: SMCourseService,
        private employeeService: EmployeeinfohdrService,
    ) {
        this.myForm = this.fb.group({
            'entityid': ['', []],
            'code': ['', []],
            'resptypeid': ['', []],
            'courseid': ['', []],
            'assignto': ['', []],
            'stxt': ['', []]
        });
    }

    getAdmissionEnquiryDetail() {
        let entityid;
        let code = 'null';
        let resptypeid;
        let courseid;
        let assignto;
        let stxt = 'null';

        if (this.myForm.controls['entityid'].value) {
            entityid = this.myForm.controls['entityid'].value;
        }

        if (this.myForm.controls['code'].value && this.myForm.controls['code'].value.trim()) {
            code = this.myForm.controls['code'].value.trim();
        }

        if (this.myForm.controls['resptypeid'].value) {
            resptypeid = this.myForm.controls['resptypeid'].value;
        }

        if (this.myForm.controls['courseid'].value) {
            courseid = this.myForm.controls['courseid'].value;
        }

        if (this.myForm.controls['assignto'].value) {
            assignto = this.myForm.controls['assignto'].value;
        }

        if (this.myForm.controls['stxt'].value && this.myForm.controls['stxt'].value.trim()) {
            stxt = this.myForm.controls['stxt'].value.trim();
        }

        this.report.invokeAction = "WebDocumentViewer/AdmissionEnquiryDetail";
        this.reportUrl = "AdmissionEnquiryDetail&api/FOAdmEnquiry/v2/AdmissionEnquiryDetail/" + entityid + "/" + code + "/" + stxt + "/" + resptypeid + "/" + courseid + "/" + assignto;
        this.report.getReportData();
    }

    async getEntity() {
        const dto = await this.entityService.getEntity();
        if (dto.IsSuccess) {
            this.Entity = dto.Data;
        }

        else {
            console.log(dto.Errors);
        }
    }

    async getResponse() {
        const dto = await this.lovService.getResponse();
        if (dto.IsSuccess) {
            this.Response = dto.Data;
        }
        else {
            console.log(dto.Errors);
        }
    }

    async getEntityCourse(entityid) {
        const dto = await this.courseservice.getEntityCourse(entityid);
        if (dto.IsSuccess) {
            this.Course = dto.Data;
        } else {
            console.log(dto.Errors);
        }
    }

    async getsectionAgainstCourse(courseid) {
        const result = await this.courseservice.getsectionAgainstCourse(courseid);
        if (result.IsSuccess) {
            this.CourseSection = result.Data;
        }
    }
    async getEmployee(entityid) {
        const dto = await this.employeeService.GetEmployeeEntity(entityid);
        if (dto.IsSuccess) {
            this.Employee = dto.Data;
        } else {
            console.log(dto.Errors);
        }
    }

    clearfilter() {
        this.myForm.controls['entityid'].setValue(undefined);
        this.myForm.controls['code'].setValue(undefined);
        this.myForm.controls['resptypeid'].setValue(undefined);
        this.myForm.controls['courseid'].setValue(undefined);
        this.myForm.controls['assignto'].setValue(undefined);
        this.myForm.controls['stxt'].setValue(undefined);
    }
}
