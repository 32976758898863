import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ReportViewerComponent } from "src/app/report-viewer/report-viewer.component";
import { InstituteService } from "src/app/Shared/institute.service";
import { LovService } from "src/app/Shared/lov.service";

@Component({
    selector: 'postalregisterdetail',
    templateUrl: 'postalregisterdetail.component.html',
})
export class PostalregisterdetailComponent implements OnInit {
    postalType: any;
    courierCompany: any;
    Entity: any;

    title = 'Visitor Book Detail';
    reportUrl = "";
    protected fb: FormBuilder = new FormBuilder();
    public myForm: FormGroup;
    @ViewChild('postalregisterdetail') report: ReportViewerComponent;

    async ngOnInit() {
        await this.getEntity();
        await this.getCourierCompany();
        await this.getPostalType();

    }

    constructor(
        private lovService: LovService,
        private entityService: InstituteService) {
        this.myForm = this.fb.group({
            'entityid': ['', []],
            'code': ['', []],
            'couriercompanyid': ['', []],
            'stxt': ['', []],
            'referenceno': ['', []],
            'fromperson': ['', []],
            'toperson': ['', []],
            'postaltypeid': ['', []],
            'startdate': ['', []],
            'enddate': ['', []],
        });
    }

    getPostalRegisterDetail() {
        let entityid = null;
        let code = 'null';
        let couriercompanyid = null;
        let stxt = 'null';
        let referenceno = 'null';
        let fromperson = 'null';
        let toperson = 'null';
        let postaltypeid = null;
        let startdate = null;
        let enddate = null;

        if (this.myForm.controls['entityid'].value) {
            entityid = this.myForm.controls['entityid'].value;
        }

        if (this.myForm.controls['code'].value && this.myForm.controls['code'].value.trim()) {
            code = this.myForm.controls['code'].value.trim();
        }

        if (this.myForm.controls['couriercompanyid'].value) {
            couriercompanyid = this.myForm.controls['couriercompanyid'].value;
        }

        if (this.myForm.controls['stxt'].value && this.myForm.controls['stxt'].value.trim()) {
            stxt = this.myForm.controls['stxt'].value.trim();
        }

        if (this.myForm.controls['referenceno'].value && this.myForm.controls['referenceno'].value.trim()) {
            referenceno = this.myForm.controls['referenceno'].value.trim();
        }

        if (this.myForm.controls['fromperson'].value && this.myForm.controls['fromperson'].value.trim()) {
            fromperson = this.myForm.controls['fromperson'].value.trim();
        }

        if (this.myForm.controls['toperson'].value && this.myForm.controls['toperson'].value.trim()) {
            toperson = this.myForm.controls['toperson'].value.trim();
        }

        if (this.myForm.controls['postaltypeid'].value) {
            postaltypeid = this.myForm.controls['postaltypeid'].value;
        }

        if (this.myForm.controls['startdate'].value) {
            startdate = this.myForm.controls['startdate'].value;
        }

        if (this.myForm.controls['enddate'].value) {
            enddate = this.myForm.controls['enddate'].value;
        }

        this.report.invokeAction = "WebDocumentViewer/PostalRegisterDetail";
        this.reportUrl = "PostalRegisterDetail&api/FOPostaldispatch/v2/PostalRegisterDetail/" + entityid + "/" + couriercompanyid + "/" + stxt + "/" + code + "/" + referenceno + "/" + fromperson + "/" + toperson + "/" + postaltypeid + "/" + startdate + "/" + enddate;
        this.report.getReportData();
    }

    async getEntity() {
        const dto = await this.entityService.getEntity();
        if (dto.IsSuccess) {
            this.Entity = dto.Data;
        }

        else {
            console.log(dto.Errors);
        }
    }

    async getCourierCompany() {

        const dto = await this.lovService.getCourierCompany();
        if (dto.IsSuccess) {
            this.courierCompany = dto.Data;
        }
        else {
            console.log(dto.Errors);
        }
    }

    async getPostalType() {

        const dto = await this.lovService.getPostalType()
        if (dto.IsSuccess) {
            this.postalType = dto.Data;
        }
        else {
            console.log(dto.Errors);
        }
    }

    clearfilter() {
        this.myForm.controls['entityid'].setValue(null);
        this.myForm.controls['code'].setValue(null);
        this.myForm.controls['couriercompanyid'].setValue(null);
        this.myForm.controls['stxt'].setValue(null);
        this.myForm.controls['referenceno'].setValue(null);
        this.myForm.controls['fromperson'].setValue(null);
        this.myForm.controls['toperson'].setValue(null);
        this.myForm.controls['postaltypeid'].setValue(null);
        this.myForm.controls['startdate'].setValue(null);
        this.myForm.controls['enddate'].setValue(null);
    }
}
