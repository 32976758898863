import { LookupConfiguration } from "../../components/lookup/lookup.config";

export const LovConfigs = {
    lister: <LookupConfiguration[]>[

        {
            name: 'code',
            caption: 'Code',
        },
        {
            name: 'stxt',
            caption: 'Short Name',
        },
        {
            name: 'ltxt',
            caption: 'Description',
        }
    ]
};

export const emploeeConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'employeecode',
            caption: 'Code'
        },
        {
            name: 'firstname',
            caption: 'First Name'
        },
        {
            name: 'lastname',
            caption: 'Last Name'
        },
        // {
        //     name:'Department',
        //     caption:'OrgDepartment.depstxt'
        // }
    ]
};

