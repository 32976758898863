import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer,
  forwardRef
} from "@angular/core";
import { BaseEditor } from "../baseEditor/base.editor";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as moment from "moment";
import { AmazingTimePickerService } from "amazing-time-picker";
import { AppConstants } from "../../app.constant";

@Component({
  selector: "ixs-time",
  templateUrl: "time.component.html",
  styleUrls: ["time.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeComponent),
      multi: true
    }
  ]
})
export class TimeComponent implements ControlValueAccessor {
  selectedItem: any[];

  constructor(
    private element: ElementRef,
    private renderer: Renderer,
    private atp: AmazingTimePickerService
  ) {}

  //for the purpose of fire event on lister select event
  public triggerChanged() {
    let event = new CustomEvent("change", { bubbles: true });
    this.renderer.invokeElementMethod(
      this.element.nativeElement,
      "dispatchEvent",
      [event]
    );
  }

  private innerValue: any = null;

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  get value(): any {
    //   const rtnVal : string  = this.innerValue != null ? moment(this.innerValue).format( 'HH:mm') : null ;
    // return rtnVal;
    return this.innerValue;
  }

  @Input()
  set value(v: any) {
    if (v !== this.innerValue && v !== null) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.triggerChanged();
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (!value) {
      this.innerValue = "";
    } else if (value !== this.innerValue) {
      const timeFormat = AppConstants.settings.timeFormat;
      const convertDate =
        this.innerValue != null
          ? moment(value).format(timeFormat ? timeFormat : "HH:mm:ss")
          : null;
      this.innerValue = convertDate !== "Invalid date" ? convertDate : value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @Input()
  caption: string;

  @Input()
  disabled: boolean = false;

  keyPress(event) {
    if (event.keyCode === 13) {
      this.openTimePicker();
    }
  }

  openTimePicker() {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      this.value = time;
    });
  }

  @Input()
  position: number = 1;

  private _fieldWidth = "input-group col-lg-3 col-md-3 col-sm-9 col-xs-9";

  @Input()
  get fieldWidth(): string {
    return this._fieldWidth;
  }
  set fieldWidth(value: string) {
    
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._fieldWidth =
          "input-group col-lg-" +
          (colSplit[0] ? colSplit[0] : "3") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "3") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3");
      }
    }
  }

  private _captionWidth = "col-lg-1 col-md-1 col-sm-3 col-xs-3 col-form-label";

  @Input()
  get captionWidth(): string {
    return this._captionWidth;
  }
  set captionWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._captionWidth =
          "col-lg-" +
          (colSplit[0] ? colSplit[0] : "1") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "1") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          "col-form-label";
      }
    }
  }
}
