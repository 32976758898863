import { Component, ViewChild, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ReportService } from "./ixs-report.service";
import { CustomService } from "./custom-service";
import { List } from "linqts";
import { async } from "q";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { NavComponent } from "../../core/component/Nav.Component";
import { AppConstants } from "../../app.constant";
import { SyncValue } from "../../core/service/syncdata.service";
import { NavModalComponent } from "../../core/component/NavModal.Component";
import { forEach } from "@angular/router/src/utils/collection";

@Component({
  selector: "ixs-report",
  templateUrl: "ixs-report.component.html"
})
export class IxsReportComponent extends NavModalComponent<any> {
  protected fb: FormBuilder = new FormBuilder();
  public myForm: FormGroup;
  private _showCriteria: boolean = true;
  private _isCollapsed: boolean = false;
  data: any = {};
  config: reportDetail[] = [];
  depandentOn = [];
  collapsed: boolean = false;
  private _isAutoGenerated: boolean = false;
  public defaultValues: Array<SyncValue> = Array<SyncValue>();

  @Input()
  get isAutoGenerated(): boolean {
    return this._isAutoGenerated;
  }

  set isAutoGenerated(value) {
    this._isAutoGenerated = value;
  }

  @Input()
  get showCriteria(): boolean {
    return this._showCriteria;
  }

  set showCriteria(value) {
    this._showCriteria = value;
  }

  @Input()
  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  set isCollapsed(value) {
    this._isCollapsed = value;
  }

  @ViewChild("frame") public iframe: HTMLIFrameElement;

  set reportName(value: string) {}
  url: any;
  constructor(
    private reportService?: ReportService,
    private customService?: CustomService,
    private sanitizer?: DomSanitizer
  ) {
    super(reportService);
    this.Modal = true;
  }

  async ngOnInit() {
    this.myForm = this.fb.group({});
    // this.showToolbar(false);
  }
  _reportCode: string;
  @Input()
  get reportCode(): string {
    return this._reportCode;
  }

  set reportCode(value) {
    this._reportCode = value;

    if (this._reportCode) {
      this.getReport(value).then(() => {
        this.generateData().then(() => {
          this.setDefaultValues().then(async () => {
            if (this.defaultValues.length > 0) {
              for (let index = 0; index < this.defaultValues.length; index++) {
                const element = this.defaultValues[index];

                this.myForm.controls[element.key].setValue(element.value);
                await this.valueChange(element.key);
              }
            }
            if (this.isAutoGenerated) this.generate();
          });
        });
      });
    }
  }

  async getReport(reportCode: string) {
    this.startLoading();
    const result = await this.reportService.GetReport(reportCode);

    if (result.IsSuccess) {
      this.stopLoading();
      this.data = result.Data;
    } else {
      this.stopLoading();
      this.showErrors(result.Errors);
    }
  }

  async getData(
    fieldname: string,
    controller: string,
    route: string,
    params: Array<any>
  ): Promise<Array<any>> {
    let returnData: any = [];
    this.customService.controller = controller;
    this.startLoading();
    const result = await this.customService.get(route, params);

    if (result.IsSuccess) {
      this.stopLoading();
      returnData = result.Data;
      this.LookupData[fieldname] = result.Data;
    } else {
      this.stopLoading();
      this.showErrors(result.Errors);
    }

    return returnData;
  }

  async generateData() {
    // utreportdtls
    this.collapsed = true;
    if (this.data && this.data.utreportdtls) {
      this.setTitle = this.data.stxt ? this.data.stxt : "";
      var dtl = new List<reportDetail>(this.data.utreportdtls)
        .OrderBy(o => o.lineindex)
        .ToArray();

      this.depandentOn = new List<reportDetail>(this.data.utreportdtls)
        .Where(o => o.dependenton !== "")
        .OrderBy(o => o.lineindex)
        .ToArray();

      var fields = {};

      for (let i = 0; i < dtl.length; i++) {
        var element = dtl[i];
        fields[element.fieldname] = [];
      }

      this.myForm = this.fb.group(fields);

      for (let index = 0; index < dtl.length; index++) {
        var element = dtl[index];

        var field: reportDetail = {
          caption: element.caption,
          fieldname: element.fieldname,
          ismandatory: element.ismandatory,
          fieldtypeid: element.fieldtypeid,
          statusid: element.statusid,
          defaultvalue: element.defaultvalue,
          capwidth: element.capwidth,
          fieldwidth: element.fieldwidth
        };

        if (element.fieldtypeid === ReportingEnum.Lov.FieldType.Combo) {
          var params: any = [];
          if (!element.dependenton) {
            var indexOf = element.parms ? element.parms.indexOf(",") : -1;

            var paramsSplit: string[] = [];
            if (indexOf !== -1) {
              paramsSplit = element.parms.split(",");
            }

            for (let i = 0; i < paramsSplit.length; i++) {
              if (paramsSplit[i] && paramsSplit[i] !== ",")
                params.push(this.myForm.controls[paramsSplit[i]].value);
            }

            var dropdownData = await this.getData(
              element.fieldname,
              element.controller,
              element.routemethod,
              params
            );
            if (dropdownData) {
              field.data = dropdownData;
            }
          }

          field.valuemember = element.valuemember;
          field.displaymember = element.displaymember;
          field.params = element.parms;
          field.controller = element.controller;
          field.routemethod = element.routemethod;
          field.utlisterconfighdr = element.utlisterconfighdr;
          if (
            element.utlisterconfighdr &&
            element.utlisterconfighdr.utlisterconfigdtls
          ) {
            element.utlisterconfighdr.utlisterconfigdtls.map(obj => {
              obj.name = obj.fieldname;
              (obj.width = obj.fieldwidth ? obj.fieldwidth + "px" : ""),
                (obj.type = obj.fieldtypeid
                  ? obj.fieldtypeid === ReportingEnum.Lov.FieldType.Date
                    ? "date"
                    : obj.fieldtypeid === ReportingEnum.Lov.FieldType.Time
                    ? "time"
                    : ""
                  : "");
              obj.format = obj.format ? obj.format : "";
            });

            field.utlisterconfigdtls = new List<listerDtl>(
              element.utlisterconfighdr.utlisterconfigdtls
            )
              .OrderBy(o => o.lineindex)
              .ToArray();
          }
        }

        this.config.push(field);
      }
    } else {
      this.showErrors("no report found!");
    }
  }

  async valueChange(fieldname: string) {
    if (this.depandentOn.length > 0) {
      var depandantsFields = this.depandentOn.filter((e: reportDetail) =>
        e.dependenton.match(fieldname)
      );

      if (depandantsFields && depandantsFields.length > 0) {
        for (let index = 0; index < depandantsFields.length; index++) {
          const element: reportDetail = depandantsFields[index];

          var params = [];
          var indexOf = element.parms ? element.parms.indexOf(",") : -1;

          var paramsSplit: string[] = [];
          if (indexOf !== -1) {
            paramsSplit = element.parms.split(",");
          }

          for (let i = 0; i < paramsSplit.length; i++) {
            if (paramsSplit[i] && paramsSplit[i] !== ",") {
              let data = this.myForm.controls[paramsSplit[i]] != undefined ? this.myForm.controls[paramsSplit[i]].value : paramsSplit[i];
              params.push(data ? data : "NULL");
            }
          }
          let checkNULL = params.filter(o => o === "NULL")[0];
          if (!checkNULL) {
            await this.getData(
              element.fieldname,
              element.controller,
              element.routemethod,
              params
            );
          } else {
            // this.LookupData[fieldname] = [];
          }
          this.myForm.controls[element.fieldname].setValue("");
        }
      }
    }
  }

  async setDefaultValues() {
    if (this.data && this.data.utreportdtls) {
      var dtl = new List<reportDetail>(this.data.utreportdtls)
        .OrderBy(o => o.lineindex)
        .ToArray();

      for (let index = 0; index < dtl.length; index++) {
        const element = dtl[index];

        if (element.defaultvalue) {
          let defaultVal = element.defaultvalue;

          if (element.defaultvalue === "{userentity}") {
            defaultVal = this.localStorage.get("selectedEntity");
          }
          if (element.fieldtypeid === ReportingEnum.Lov.FieldType.Date) {
            element.defaultvalue = element.defaultvalue.replace("{", "");
            element.defaultvalue = element.defaultvalue.replace("}", "");
            const defaultValues =
              element.defaultvalue.indexOf(",") !== -1
                ? element.defaultvalue.split(",")
                : [];

            if (defaultValues && defaultValues.length === 3) {
              var date = new Date();
              let type = defaultValues[1];

              if (type === "D") {
                date.setDate(date.getDate() + Number(defaultValues[2]));
              } else if (type === "M") {
                date.setMonth(date.getMonth() + Number(defaultValues[2]));
              } else if (type === "Y") {
                date.setFullYear(date.getFullYear() + Number(defaultValues[2]));
              }

              defaultVal = moment(date).format("YYYY-MM-DD");
            }
          }

          this.myForm.controls[element.fieldname].setValue(defaultVal);

          await this.valueChange(element.fieldname);
        }
      }
    }
  }

  private validate(): boolean {
    let isDirty: boolean;
    let ctrl;
    let mandatoryFields = [];

    Object.keys(this.myForm.controls).map(controlName => {
      ctrl = this.myForm.get(controlName);
      ctrl.markAsDirty({ onlySelf: true });
      ctrl.markAsTouched({ onlySelf: true });

      if (!isDirty) {
        let ctrlConfig = this.config.filter(
          o => o.fieldname === controlName
        )[0];
        isDirty = ctrl.errors !== null;
        
        if (
          (isDirty && !this.myForm.controls[controlName].value) ||
          (ctrlConfig.ismandatory &&
            this.myForm.controls[controlName].value === 0)
        ) {
          isDirty = true;
          mandatoryFields.push({
            Description:
              (ctrlConfig ? ctrlConfig.caption : controlName) +
              " cannot be empty"
          });
        }
      }
    });
    if (mandatoryFields.length > 0) {
      this.showErrors(mandatoryFields);
    }
    return isDirty;
  }

  private setReportValues() {
    Object.keys(this.myForm.value).forEach(name => {
      this.myForm.controls[name].setValue(undefined);
    });
  }

  async clear() {
    // this.showCriteria = false;
    Object.keys(this.myForm.controls).map(controlName => {
      this.myForm.get(controlName).markAsUntouched({ onlySelf: true });
      this.myForm.get(controlName).markAsPristine({ onlySelf: true });
    });

    this.setReportValues();
    await this.setDefaultValues();

    this.depandentOn.forEach((element: reportDetail) => {
      delete this.LookupData[element.fieldname];
    });
    this.url = undefined;
  }

  generate() {
    this.startLoading();
    if (!this.validate()) {
      //this.showCriteria = true;
      // this.report.invokeAction = this.data.reportroute;
      var routeUrl: string = this.data.routeurl;

      let routeSplit = this.data.routeurl.split("/{");

      if (routeSplit && routeSplit.length > 0) {
        for (let index = 0; index < routeSplit.length; index++) {
          if (routeSplit[index].indexOf("}") !== -1) {
            let ctrlName = routeSplit[index].replace("}", "");
            let val =
              this.myForm.controls[ctrlName] &&
              this.myForm.controls[ctrlName].value
                ? this.myForm.controls[ctrlName].value
                : "null";

            routeUrl = routeUrl.replace(
              "{" + ctrlName + "}",
              val ? val : "null"
            );
          }
        }
      }

      // this.url = this.data ? this.data.reportname + "&" + routeUrl : "";
      //this.url = "http://localhost:6005/WebForms/ReportViewer.aspx";
      // this.report.getReportData();
      //this.iframe.src = this.url;

      var subReports = [];
      if (this.data && this.data.utsubreports) {
        for (let index = 0; index < this.data.utsubreports.length; index++) {
          debugger;
          const subReport = this.data.utsubreports[index];

          if (subReport && subReport.reportname && subReport.routeurl) {
            let routeSplit = subReport.routeurl.split("/{");
            var subRouteUrl = subReport.routeurl;

            if (routeSplit && routeSplit.length > 0) {
              for (let index1 = 0; index1 < routeSplit.length; index1++) {
                debugger;
                if (routeSplit[index1].indexOf("}") !== -1) {
                  let ctrlName = routeSplit[index1].replace("}", "");
                  let val =
                    this.myForm.controls[ctrlName] &&
                    this.myForm.controls[ctrlName].value
                      ? this.myForm.controls[ctrlName].value
                      : "null";

                  subRouteUrl = subRouteUrl.replace(
                    "{" + ctrlName + "}",
                    val ? val : "null"
                  );
                }
              }
              subReports.push({
                reportname: subReport.reportname,
                routeurl: subRouteUrl
              });
            }
          }
        }
      }
      let entityid =
        this.myForm.controls["entityid"] &&
        this.myForm.controls["entityid"].value
          ? this.myForm.controls["entityid"].value
          : this.localStorage.get("selectedEntity");
      this.url =
        AppConstants.urls.api.reportUrl +
        "?" +
        this.data.reportname +
        "&&" +
        routeUrl +
        "&&" +
        entityid +
        "&&" +
        this.data.stxt +
        "&&" +
        this.localStorage.get("token").replace("bearer ", "") +
        "&&" +
        JSON.stringify(subReports);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        // "http://localhost:6005/WebForms/ReportViewer.aspx?abc=11"
        this.url
      );

      //this.iframe.blur();
    } else {
      this.stopLoading();
    }
  }

  loadEnd() {
    this.stopLoading();
    /// this.url = AppConstants.urls.reportUrl;
  }
}

interface reportDetail {
  caption: string;
  controller?: string;
  routemethod?: string;
  parms?: string;
  valuemember?: string;
  displaymember?: string;
  data?: Array<any>;
  defaultvalue?: any;
  fieldname?: string;
  ismandatory?: boolean;
  lineindex?: number;
  fieldtypeid?: number;
  statusid?: number;
  dependenton?: string;
  params?: string;
  utlisterconfighdr?: { utlisterconfigdtls?: Array<any> };
  utlisterconfigdtls?: Array<any>;
  capwidth?: string;
  fieldwidth?: string;
}

const ReportingEnum = {
  Lov: {
    FieldType: {
      Text: 16,
      Date: 17,
      Combo: 18,
      Numeric: 19,
      Checkbox: 20,
      Time: 26
    },

    ControlStatus: {
      Enable: 21,
      Disable: 22,
      Hide: 23
    }
  }
};

interface listerDtl {
  lineindex?: number;
  fieldname?: string;
  name?: string;
  caption: string;
  fieldwidth?: number;
  fieldtypeid?: number;
  format?: string;
  type?: string;
  width?: string;
}
