import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { HomeComponent } from "./home.component";
import { InstituteService } from "../organizational-setup/setup/institute/institute.service";

@NgModule({
  imports: [FormsModule],
  exports: [],
  declarations: [HomeComponent],
  providers: [InstituteService]
})
export class HomeModule {}
