// Angular Imports
import { NgModule } from "@angular/core";

// This Module's Components
import { BrowserModule } from "@angular/platform-browser";
import { ReportViewerModule } from "src/app/report-viewer/report-viewer.module";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { AdmissionenquirydetailComponent } from "./admissionenquirydetail.component";
import { InstituteService } from "src/app/Shared/institute.service";
import { LovService } from "src/app/Shared/lov.service";
import { SMCourseService } from "src/app/Shared/smcourse.service";
import { EmployeeinfohdrService } from "src/app/Shared/employee.service";
import { ComponentModule } from "src/app/components/component.module";
import { IxsReportModule } from "src/app/components/ixs-report/ixs-report.module";

@NgModule({
  imports: [
    BrowserModule,
    ReportViewerModule,
    NgxErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentModule,
    IxsReportModule
  ],
  declarations: [AdmissionenquirydetailComponent],
  exports: [AdmissionenquirydetailComponent],
  providers: [
    InstituteService,
    LovService,
    SMCourseService,
    EmployeeinfohdrService
  ]
})
export class AdmissionenquirydetailModule {}
