import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { List } from "linqts";
import { ToastsManager } from "ng6-toastr";

import { UserService } from "../core/service/user.service";
import { LocalStorage } from "../core/helper/localStorage";
import { BaseComponent } from "../core/component/Base.Component";
import { AppConstants } from "../app.constant";
import { IxsloaderComponent } from "../components/ixsloader/ixsloader.component";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["login.component.css"]
})
export class LoginComponent extends BaseComponent {
  show_button: Boolean = false;
  show_eye: Boolean = false;
  url
  model: any = {};
  private instanceList = [];
  returnUrl: string;
  dto;
  appName: string = "";

  constructor(private userService: UserService, private route: ActivatedRoute) {
    super();
    this.localStorage.clear();
    this.appName = AppConstants.settings.appName;
  }

  @ViewChild(IxsloaderComponent) loader: IxsloaderComponent;

  async login() {
    this.loader.startLoading();
    this.dto = await this.userService.login(
      this.model.username,
      this.model.password
    );
    if (this.dto.IsSuccess) {
      this.localStorage.set(
        "token",
        "bearer ".concat(this.dto.Data.Session.token)
      );
      const users = this.dto.Data.User;
      const username = users.username;
      const fullname = users.fullname;
      const userpic = users.userpic;
      const clients = users.utuserclients;
      const utuserents = users.utuserents;
      this.localStorage.set("utuserents", JSON.stringify(utuserents));
      this.localStorage.set("utapp", JSON.stringify(this.dto.Data.Apps));
      const clientInfo = new List<any>(clients)
        .Where(x => x.isdefault === true)
        .FirstOrDefault().utclient;
      this.localStorage.set("Fullname", fullname);
      this.localStorage.set("User", username);
      this.localStorage.set("userpic", userpic);
      this.localStorage.set("ClientStxt", clientInfo.clientstxt);
      this.localStorage.set("ClientCode", clientInfo.clientcode);
      this.localStorage.set("userclients", JSON.stringify(clients));
      this.localStorage.set("clientid", clientInfo.clientid);
      const appInfo = new List<any>(this.dto.Data.Apps).FirstOrDefault(
        x => x.appcode === AppConstants.settings.appCode
      );

      if (appInfo) {
        const instanceInfo = new List<any>(
          appInfo.utdatabase.utinstances
        ).FirstOrDefault(
          x => x.instancecode === AppConstants.settings.instancecode
        );

        if (instanceInfo) {
          this.localStorage.set("apiUrl", instanceInfo.apiurl + "api/");
          this.localStorage.set("OnlyUrl", instanceInfo.apiurl);

          //this.showSuccess("Logged in successfully", "Authenticated");

          this.navigate("userclients");
        } else {
          this.showErrors("Instance does not exist.");
        }
      } else {
        this.showErrors("User is not assigned this application.");
      }
      this.loader.stopLoading();
    } else {
      this.loader.stopLoading();
      this.showErrors(this.dto.Errors);
    }
  }
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
}
