import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private router: Router;


    constructor(private injector: Injector) {
    }

    handleError(error) {
        debugger
        if (!this.router) {
            this.router = this.injector.get(Router);
        }
        
        const errObject = JSON.parse(error.message);
        if (errObject && errObject.status === 401) {

            localStorage.setItem('token', "");
            this.router.navigate(['login']);
        }
    }
}