import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ReportViewerComponent } from "src/app/report-viewer/report-viewer.component";
import { InstituteService } from "src/app/Shared/institute.service";
import { FOPurposeService } from "src/app/Shared/purpose.service";

@Component({
    selector: 'visitorbookdetail',
    templateUrl: 'visitorbookdetail.component.html',
})
export class VisitorbookdetailComponent implements OnInit {
    Purpose: any;
    Entity: any;
    Course: any;
    AcademicYear: any;
    TermExam: any;
    Section: any;
    title = 'Visitor Book Detail';
    reportUrl = "";
    protected fb: FormBuilder = new FormBuilder();
    public myForm: FormGroup;
    @ViewChild('visitorbookdetail') report: ReportViewerComponent;

    async ngOnInit() {
        await this.getEntity();
        await this.getPurposeType();
    }

    constructor(
        // private lovService: LovService,
        private entityService: InstituteService,
        private purposeService: FOPurposeService,
    ) {
        this.myForm = this.fb.group({
            'entityid': ['', []],
            'code': ['', []],
            'purposetypeid': ['', []],
            'stxt': ['', []],
            'idcard': ['', []],
            'cellno': ['', []],
            'startdate': ['', []],
            'enddate': ['', []],
        });
    }

    getVisitorBookDetail() {
        let entityid;
        let code = 'null';
        let visitor = 'null';
        let purposetypeid;
        let startdate;
        let enddate;
        let idcard = 'null';
        let cellno = 'null';

        if (this.myForm.controls['entityid'].value) {
            entityid = this.myForm.controls['entityid'].value;
        }

        if (this.myForm.controls['code'].value && this.myForm.controls['code'].value.trim()) {
            code = this.myForm.controls['code'].value.trim();
        }

        if (this.myForm.controls['stxt'].value && this.myForm.controls['stxt'].value.trim()) {
            visitor = this.myForm.controls['stxt'].value.trim();
        }

        if (this.myForm.controls['purposetypeid'].value) {
            purposetypeid = this.myForm.controls['purposetypeid'].value;
        }

        if (this.myForm.controls['idcard'].value && this.myForm.controls['idcard'].value.trim()) {
            idcard = this.myForm.controls['idcard'].value.trim();
        }

        if (this.myForm.controls['cellno'].value && this.myForm.controls['cellno'].value.trim()) {
            cellno = this.myForm.controls['cellno'].value.trim();
        }

        if (this.myForm.controls['startdate'].value) {
            startdate = this.myForm.controls['startdate'].value;
        }

        if (this.myForm.controls['enddate'].value) {
            enddate = this.myForm.controls['enddate'].value;
        }

        this.report.invokeAction = "WebDocumentViewer/VisitorBookDetail";
        this.reportUrl = "VisitorBookDetail&api/FOVisitorbook/v2/VisitorBookDetail/" + entityid + "/" + purposetypeid + "/" + code + "/" + visitor + "/" + idcard + "/" + cellno + "/" + startdate + "/" + enddate;
        this.report.getReportData();
    }

    async getEntity() {
        const dto = await this.entityService.getEntity();
        if (dto.IsSuccess) {
            this.Entity = dto.Data;
        }

        else {
            console.log(dto.Errors);
        }
    }

    async getPurposeType() {

        const dto = await this.purposeService.getPurposeType();
        if (dto.IsSuccess) {
            this.Purpose = dto.Data;
        }
        else {
            console.log(dto.Errors);
        }
    }

    clearfilter() {
        this.myForm.controls['entityid'].setValue(undefined);
        this.myForm.controls['code'].setValue(undefined);
        this.myForm.controls['purposetypeid'].setValue(undefined);
        this.myForm.controls['stxt'].setValue(undefined);
        this.myForm.controls['idcard'].setValue(undefined);
        this.myForm.controls['cellno'].setValue(undefined);
        this.myForm.controls['startdate'].setValue(undefined);
        this.myForm.controls['enddate'].setValue(undefined);
    }
}