// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { SmFeechallanhdrComponent } from './sm-feechallanhdr.component';
import { BrowserModule } from "@angular/platform-browser";
import { ReportViewerModule } from "src/app/report-viewer/report-viewer.module";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { OrgLegalentityService } from "src/app/Reports/OrgLegalentity/OrgLegalentity.Service";
import { LovService } from "src/app/Shared/lov.service";
import { SMStudentinfoService } from "src/app/Shared/studentprofile.service";
import { SMApplicationService } from "src/app/Shared/SMAdmApplication.service";
import { SMCourseService } from "src/app/Shared/smcourse.service";

@NgModule({
    imports: [BrowserModule, ReportViewerModule, NgxErrorsModule, FormsModule, ReactiveFormsModule],
    declarations: [SmFeechallanhdrComponent,],
    exports: [SmFeechallanhdrComponent,],
    providers: [OrgLegalentityService, LovService, SMStudentinfoService, SMApplicationService, SMCourseService]
})

export class SmFeechallanhdrModule {

}
