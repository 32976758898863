// Angular Imports
import { NgModule } from "@angular/core";

// This Module's Components
import { BrowserModule } from "@angular/platform-browser";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { IxsReportComponent } from "./ixs-report.component";
import { ReportService } from "./ixs-report.service";
import { CustomService } from "./custom-service";
import { ComponentModule } from "../component.module";

@NgModule({
  imports: [
    BrowserModule,
    NgxErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentModule
  ],
  declarations: [IxsReportComponent],
  exports: [IxsReportComponent],
  providers: [ReportService, CustomService]
})
export class IxsReportModule {}
