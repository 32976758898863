import { LovService } from "./../../../../shared/lov.service";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { InstituteComponent } from "./institute.component";
import { InstituteService } from "./institute.service";

import { CountryService } from "../../../../shared/country.service";
import { CityService } from "../../../../shared/city.service";

import { BsModalModule } from "ng2-bs3-modal";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { TextMaskModule } from "angular2-text-mask";

import { ComponentModule } from "../../../../../components/component.module";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TextMaskModule,
    NgxErrorsModule,
    ComponentModule,
    BsModalModule
  ],
  exports: [InstituteComponent],
  declarations: [InstituteComponent],
  providers: [InstituteService, CountryService, CityService, LovService]
})
export class InstituteModule {}
