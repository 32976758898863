import { Injectable } from '@angular/core'
import { AppService } from "src/app/core/service/Base.Service";

@Injectable()
export class SMCourseService extends AppService<any>{

    constructor() {
        super('SMCourse');
    }

    async getEntityCourse(entityid) {
        if (entityid > 0) {
            const result = await this.get('EntityCourse', [entityid]);
            return result;
        }
    }
    async getsectionAgainstCourse(courseid) {
        if (courseid) {
            const result = await this.get('SectionAgainstCourse', [courseid]);
            return result;
        }
    }
}