import { Component } from '@angular/core';
import { OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { ReportViewerComponent } from "src/app/report-viewer/report-viewer.component";
import { ViewChild } from "@angular/core";
import { LovService } from "src/app/Shared/lov.service";
import { SMAcademicYearService } from "src/app/Shared/smacademicyear.service";
import { SMCourseService } from "src/app/Shared/smcourse.service";
import { InstituteService } from "src/app/Shared/institute.service";

@Component({
    selector: 'examschedule',
    templateUrl: 'examschedule.component.html',
})

export class ExamscheduleComponent implements OnInit {
    Entity: any;
    Course: any;
    AcademicYear: any;
    TermExam: any;
    Section: any;
    title = 'Exam Schedule';
    reportUrl = "";
    protected fb: FormBuilder = new FormBuilder();
    public myForm: FormGroup;
    @ViewChild('examschedule') report: ReportViewerComponent;

    async ngOnInit() {
        await this.getEntity();
        await this.getTerm();
    }

    constructor(
        private lovService: LovService,
        private smAcademicYearService: SMAcademicYearService,
        private courseservice: SMCourseService,
        private entityService: InstituteService
    ) {
        this.myForm = this.fb.group({
            'entityid': ['', []],
            'code': ['', []],
            'examtypeid': ['', []],
            'acdyearid': ['', []],
            'courseid': ['', []],
            'stxt': ['', []],
            'startdate': [' ', []],
            'enddate': ['', []],
        });
    }

    getExamSchedule() {
        let entityid;
        let code = 'null';
        let exam = 'null';
        let examtypeid;
        let acdyearid;
        let courseid;
        let startdate;
        let enddate;

        if (this.myForm.controls['entityid'].value) {
            entityid = this.myForm.controls['entityid'].value;
        }

        if (this.myForm.controls['code'].value && this.myForm.controls['code'].value.trim()) {
            code = this.myForm.controls['code'].value.trim();
        }

        if (this.myForm.controls['stxt'].value && this.myForm.controls['stxt'].value.trim()) {
            exam = this.myForm.controls['stxt'].value.trim();
        }

        if (this.myForm.controls['examtypeid'].value) {
            examtypeid = this.myForm.controls['examtypeid'].value;
        }

        if (this.myForm.controls['acdyearid'].value) {
            acdyearid = this.myForm.controls['acdyearid'].value;
        }

        if (this.myForm.controls['courseid'].value) {
            courseid = this.myForm.controls['courseid'].value;
        }

        if (this.myForm.controls['startdate'].value) {
            startdate = this.myForm.controls['startdate'].value;
        }

        if (this.myForm.controls['enddate'].value) {
            enddate = this.myForm.controls['enddate'].value;
        }

        this.report.invokeAction = "WebDocumentViewer/ExamSchedule";
        this.reportUrl = "ExamSchedule&api/SMExamSchedulehdr/v2/ExamSchedule/" + entityid + "/" + code + "/" + exam + "/" + examtypeid + "/" + acdyearid + "/" + courseid + "/" + startdate + "/" + enddate;
        this.report.getReportData();
    }

    async getTerm() {
        const dto = await this.lovService.getTerm();
        if (dto.IsSuccess) {
            this.TermExam = dto.Data;
        }
    }

    async getEntityAcademicyear(entityid) {
        const dto = await this.smAcademicYearService.getEntityAcademicyear(entityid);
        if (dto.IsSuccess) {
            this.AcademicYear = dto.Data;
        } else {
            console.log(dto.Errors);
        }
    }

    async getEntityCourse(entityid) {
        const dto = await this.courseservice.getEntityCourse(entityid);
        if (dto.IsSuccess) {
            this.Course = dto.Data;
        } else {
            console.log(dto.Errors);
        }
    }

    async getEntity() {
        const dto = await this.entityService.getEntity();
        if (dto.IsSuccess) {
            this.Entity = dto.Data;
        }

        else {
            console.log(dto.Errors);
        }
    }

    clearfilter() {
        this.myForm.controls['entityid'].setValue(undefined);
        this.myForm.controls['code'].setValue(undefined);
        this.myForm.controls['examtypeid'].setValue(undefined);
        this.myForm.controls['acdyearid'].setValue(undefined);
        this.myForm.controls['courseid'].setValue(undefined);
        this.myForm.controls['stxt'].setValue(undefined);
        this.myForm.controls['startdate'].setValue(undefined);
        this.myForm.controls['enddate'].setValue(undefined);
    }
}