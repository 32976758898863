import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer,
  forwardRef
} from "@angular/core";
import { BaseEditor } from "../baseEditor/base.editor";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "ixs-date",
  templateUrl: "date.component.html",
  styleUrls: ["date.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    }
  ]
})
export class DateComponent implements ControlValueAccessor {
  selectedItem: any[];

  constructor(private element: ElementRef, private renderer: Renderer) {}

  //for the purpose of fire event on lister select event
  public triggerChanged() {
    let event = new CustomEvent("change", { bubbles: true });
    this.renderer.invokeElementMethod(
      this.element.nativeElement,
      "dispatchEvent",
      [event]
    );
  }

  private innerValue: any = "";

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  get value(): any {
    const rtnVal: string = moment(this.innerValue).format(
      this.type === "month" ? "YYYY-MM" : "YYYY-MM-DD"
    );
    return rtnVal;
  }

  @Input()
  set value(v: any) {
    if (v !== this.innerValue && v !== null) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (!value) {
      this.innerValue = "";
    } else if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @Input()
  caption: string;

  @Input()
  disabled: boolean = false;

  @Input()
  type: string = "date";

  @Input()
  position: number = 1;

  private _fieldWidth = "input-group col-lg-3 col-md-3 col-sm-9 col-xs-9";

  @Input()
  get fieldWidth(): string {
    return this._fieldWidth;
  }
  set fieldWidth(value: string) {
    
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._fieldWidth =
          "input-group col-lg-" +
          (colSplit[0] ? colSplit[0] : "3") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "3") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3");
      }
    }
  }

  private _captionWidth = "col-lg-1 col-md-1 col-sm-3 col-xs-3 col-form-label";

  @Input()
  get captionWidth(): string {
    return this._captionWidth;
  }
  set captionWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._captionWidth =
          "col-lg-" +
          (colSplit[0] ? colSplit[0] : "1") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "1") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          "col-form-label";
      }
    }
  }
}
