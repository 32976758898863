import { Component, OnInit } from '@angular/core';

import { ToolbarEvent } from '../../core/events/toolbar.event';
import { FormBuilder, Validators } from '@angular/forms';
import { ContainerComponent } from '../../core/component/Container.Component';
import { Router } from '@angular/router';
@Component({
	selector: 'app-container',
	templateUrl: 'formcontainer.component.html'
})

export class AppContainerComponent extends ContainerComponent {

	constructor(private toolbarEvent: ToolbarEvent, private fb: FormBuilder, public router: Router) {
		super();
		this.myForm = this.fb.group({});
	}

	async ngOnInit() {
	}

	Back() {
		this.router.navigate(['home']);
	}
}
