import {
  Component,
  OnInit,
  ReflectiveInjector,
  Injector,
  ViewChild
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { ToastsManager } from "ng6-toastr";

import { ToolbarEvent } from "../../core/events/toolbar.event";
import { BaseService } from "../service/Base.Service";
import { DataTransferObject } from "../entities/DataTransferObject";
import { AppConstants } from "../../app.constant";
import { LocalStorage } from "../helper/localStorage";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { List } from "linqts";
import { BsModalComponent } from "ng2-bs3-modal";
import { LookupComponent } from "../../components/lookup/lookup.component";
import { LookupConfiguration } from "../../components/lookup/lookup.config";
import { Broadcast } from "../events/broadcast";
import { ErrorObject } from "../entities/ErrorObject";
import { MessageBoxComponent } from "../../components/messagebox/messagebox.component";
import {
  MessageBoxSettings,
  MessageBoxType
} from "../../components/messagebox/messagebox.config";
import { SyncDataService } from "../service/syncdata.service";
import { IxsloaderComponent } from "../../components/ixsloader/ixsloader.component";

export abstract class BaseComponent implements OnInit {
  public router: Router;
  private toaster: ToastsManager;
  public SyncData: SyncDataService;
  protected activatedRoute: ActivatedRoute;
  protected localStorage: LocalStorage;
  protected broadcast: Broadcast;

  public LookupData: any = {};

  @ViewChild("ixslookup")
  public lookup: LookupComponent;
  public lookupConfigs: any = {};
  constructor() {
    this.toaster = AppConstants.injector.get(ToastsManager);
    this.activatedRoute = AppConstants.injector.get(ActivatedRoute);
    this.router = AppConstants.injector.get(Router);
    this.localStorage = AppConstants.injector.get(LocalStorage);
    this.broadcast = AppConstants.injector.get(Broadcast);
    this.SyncData = AppConstants.injector.get(SyncDataService);
  }

  async ngOnInit() {}

  makeDropDownData(data, valueField: string, labelField: string) {
    return new List<any>(data)
      .Select(
        x =>
          <any>{
            value: x[valueField],
            label: x[labelField]
          }
      )
      .ToArray();
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  showLookup(
    config: LookupConfiguration[],
    data,
    lookupId: string,
    caption: String
  ) {
    
    this.lookup.Config = config;
    this.lookup.Data = data;
    this.lookup.Caption = caption;
    this.lookup.LookupId = lookupId;
    this.lookup.open();
  }

  protected async showToolbar(value: Boolean) {
    await this.broadcast.publish<Boolean>("showToolbar", value);
  }

  protected async hideAddToolbar(value: Boolean) {
    await this.broadcast.publish<Boolean>("hideadd", value);
  }

  protected async hideDeleteToolbar(value: Boolean) {
    await this.broadcast.publish<Boolean>("hidedelete", value);
  }

  protected async showYesNo(id: string, message: string, title?: string) {
    await this.broadcast.publish<MessageBoxSettings>("showMessage", <
      MessageBoxSettings
    >{
      id,
      type: MessageBoxType.yesNo,
      caption: title,
      message: message
    });
  }

  protected showErrors(messages: string | ErrorObject[], title?: string) {
    let msg;

    if (messages) {
      if (typeof messages === "string") {
        msg = messages;
      } else {
        msg = "";
        messages.forEach(error => {
          msg += error.Description;
          msg += "<br/>";
        });
      }

      this.toaster.error(msg, title, { enableHTML: true });
    }
  }

  protected showSuccess(messages: string | string[], title?: string) {
    let msg;

    if (messages) {
      if (typeof messages === "string") {
        msg = messages;
      } else {
        msg = "";
        messages.forEach(error => {
          msg += error;
          msg += "<br/>";
        });
      }

      this.toaster.success(msg, title, { enableHTML: true });
    }
  }

  protected showWarnings(messages: string | string[], title?: string) {
    let msg;

    if (messages) {
      if (typeof messages === "string") {
        msg = messages;
      } else {
        msg = "";
        messages.forEach(error => {
          msg += error;
          msg += "<br/>";
        });
      }

      this.toaster.warning(msg, title, { enableHTML: true });
    }
  }

  protected showInfo(messages: string | string[], title?: string) {
    let msg;

    if (messages) {
      if (typeof messages === "string") {
        msg = messages;
      } else {
        msg = "";
        messages.forEach(error => {
          msg += error;
          msg += "<br/>";
        });
      }

      this.toaster.info(msg, title, { enableHTML: true });
    }
  }
}
