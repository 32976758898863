import { Injectable } from "@angular/core";
import { UtService } from "../../core/service/Base.Service";

@Injectable()
export class ReportService extends UtService<any> {
  constructor() {
    super("utreporthdr");
  }

  async GetReport(reportCode) {
    const rslt = await this.get("Report", reportCode);
    return rslt;
  }
}
