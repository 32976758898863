import { Component, ViewContainerRef } from "@angular/core";
import { ToastsManager } from "ng6-toastr";
import "../../node_modules/primeng/resources/themes/omega/theme.css";
import "../../node_modules/primeng/resources/primeng.min.css";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";

@Component({
  selector: "app-root",
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent {
  constructor(
    private _toastr: ToastsManager,
    private vRef: ViewContainerRef,
    private route: Router
  ) {
    this._toastr.setRootViewContainerRef(vRef);
    this.routeEvent(this.route);
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        console.log(e);

        let arr = e.url.split("?");

        if (arr.length >= 2) {
          var tokenSplit = arr[1].split("=&");
          if (tokenSplit.length >= 2) {
            
            for (let index = 0; index < tokenSplit.length; index++) {
              const element = tokenSplit[index].split(",");
              if(element && element.length >= 2 && element[0] && element[1]){
              let data = element[1].replace(/%2F/g,"/").replace(/%20/g," ").replace("=","");
              if(data.match("%7B%") === null){
              localStorage.setItem(element[0],data);
              }
              }
              
            }
         
        }
      }
    }
  });
  }
}
