import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../../core/helper/auth.guard';

import { LocalStorage } from '../../core/helper/localStorage';
import { AppConstants } from '../../app.constant';
import { BaseComponent } from '../../core/component/Base.Component';

@Component({
	
	selector: "navigation-bar",
	templateUrl: '../shell-templates/navigation-bar.component.html'
})

export class NavigationBarComponent extends BaseComponent {
	protected localStorage: LocalStorage;

	constructor(private auth: AuthGuard) {
		super();
		this.localStorage = AppConstants.injector.get(LocalStorage);
	}

	async ngOnInit() { }

	title = this.localStorage.get('ClientStxt');
	title_short = this.localStorage.get('ClientCode');
	username = this.localStorage.get('User');
	fullname = this.localStorage.get('Fullname');
	userpic = this.localStorage.get('userpic');
	image = this.localStorage.get('Image');
	EntityLogo = this.localStorage.get('EntityLogo');
	ShortName = this.localStorage.get('ShortName');

	logout() {
		this.navigate('/login');
	}
}
