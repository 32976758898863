import { Injectable } from '@angular/core';
import { AppService } from "src/app/core/service/Base.Service";

@Injectable()
export class InstituteService extends AppService<any> {

    constructor() {
        super('Legalentity');
    }

    async getEntity() {
        const result = await this.get('Entity');
        return result;
    }
}


