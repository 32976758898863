

import { AppService } from "src/app/core/service/Base.Service";
import { Injectable } from "@angular/core";

@Injectable()
export class CourseService extends AppService<any>{

        constructor(){
            super('SMCourse')
        }

async getSection(courseid:number){
   const result = await this.get('SectionAgainstCourse',[courseid])
   return result;
}
        
}