import {
  Component,
  ViewChild,
  AfterViewInit,
  Renderer2,
  Input,
  ElementRef
} from "@angular/core";
import * as ko from "knockout";
import { Html } from "devexpress-reporting/dx-web-document-viewer";
import { AppConstants } from "../app.constant";

@Component({
  selector: "report-viewer",
  templateUrl: "./report-viewer.component.html"
})
export class ReportViewerComponent implements AfterViewInit {
  koReportUrl = ko.observable(null);
  _reportUrl;

  reportUrls = this.koReportUrl;
  host = AppConstants.urls.api.reportUrl;
  invokeAction = "WebDocumentViewer/Institute";

  @ViewChild("scripts")
  scripts: ElementRef;

  @ViewChild("control")
  control: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    // Use this line if you use an ASP.NET MVC backend

    // Uncomment this line if you use an ASP.NET Core backend
    // invokeAction = "DXXRDV"
    var container = this.renderer.createElement("div");

    container.innerHTML = Html;
    this.renderer.appendChild(this.scripts.nativeElement, container);
  }

  @Input()
  set reportUrl(reportUrl: string) {
    this._reportUrl = reportUrl;
    this.koReportUrl(reportUrl);
  }
  get reportUrl() {
    return this._reportUrl;
  }

  getReportData() {
    ko.cleanNode(this.control.nativeElement);
    ko.applyBindings(
      {
        reportUrl: this.reportUrls, // The URL of a report that is opened in the Document Viewer when the application starts.
        requestOptions: {
          // Options for processing requests from the Web Document Viewer.
          host: this.host, // URI of your backend project.
          invokeAction: this.invokeAction // The URI path of the controller action that processes requests.
        }
      },
      this.control.nativeElement
    );
  }
}
