import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injector } from "@angular/core";
import { HttpModule } from "@angular/http";
import { StudentstatementModule } from "src/app/Reports/Finance/Student-Statement/studentstatement.module";

import { CoreModule } from "./core/core.module";
import { ComponentModule } from "./components/component.module";
import { AppConstants } from "./app.constant";

import { SmModule } from "./shell/sm/sm.module";
import { LoginModule } from "./login/login.module";
import { UserClientsModule } from "./userclients/userclients.module";

import { ToastModule, ToastsManager, ToastOptions } from "ng6-toastr";
import { ToastyModule } from "ng2-toasty";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToolbarEvent } from "./core/events/toolbar.event";
import { IxsReportModule } from "./components/ixs-report/ixs-report.module";
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./page-not-found.component";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";

// Toaster
export class CustomOption extends ToastOptions {
  animate = "flyRight";
  positionClass = "toast-top-right";
  showCloseButton = true;
  toastLife = 2000;
  newestOnTop = false;
  //dismiss = "controlled";
}

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    HttpModule,
    BrowserModule,
    RouterModule,
    CoreModule,
    IxsReportModule,
    ComponentModule,
    ToastModule,
    LoginModule,
    UserClientsModule,
    SmModule,
    AppRoutingModule,
    FormsModule,
    ToastyModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    ToastsManager,
    ToolbarEvent,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ToastOptions, useClass: CustomOption }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppConstants.injector = injector;
  }
}
