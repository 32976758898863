import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { ViewChild } from "@angular/core";
import { ReportViewerComponent } from "src/app/report-viewer/report-viewer.component";
import { CourseService } from "src/app/Reports/student-report/course.service";
import { OnInit } from "@angular/core";
import { BaseComponent } from "src/app/core/component/Base.Component";

@Component({
  selector: "student-report",
  templateUrl: "student-report.component.html"
})
export class StudentReportComponent extends BaseComponent {
  Section: any;
  reportCode: string;
  async ngOnInit() {
    
    if (this.router && this.router.url) {
      let val = this.router.url.replace("/", "");

      this.reportCode = val;
    }
  }
  Course: any;

  title = "app";
  reportUrl = "";
  protected fb: FormBuilder = new FormBuilder();
  public myForm: FormGroup;

  constructor(private courseService: CourseService) {
    super();
    this.myForm = this.fb.group({
      sectionid: ["", []],
      courseid: ["", []],
      depltxt: ["", []]
    });
  }

  @ViewChild("rpt") report: ReportViewerComponent;

  getData() {
    this.report.invokeAction = "WebDocumentViewer/Institute";
    this.reportUrl = "MyReport&api/City/v2/";
    this.report.getReportData();
  }

  getRoute() {
    this.report.invokeAction = "WebDocumentViewer/Invoke";
    this.reportUrl = "Route&api/Route/v2/";
    this.report.getReportData();
  }

  getStudent() {
    // let val = (<HTMLSelectElement>document.getElementById('sectionid')).value;
    this.report.invokeAction = "WebDocumentViewer/Student";
    this.reportUrl =
      "Student&api/SMPromoteStudent/v2/CourseSectionList/2108/" +
      this.myForm.value.sectionid;

    this.report.getReportData();
  }

  async getCourse() {
    const result = await this.courseService.getAll();
    if (result.IsSuccess) {
      this.Course = result.Data;
    }
  }

  async getSection(courseid: number) {
    const result = await this.courseService.getSection(courseid);
    if (result.IsSuccess) {
      this.Section = result.Data;
    }
  }
}
