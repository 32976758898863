import { Injectable } from "@angular/core";
import { UtService } from "./Base.Service";

@Injectable()
export class MenusService extends UtService<any> {
  constructor() {
    super("utmenu");
  }

  async GetMenus(appcode) {
    const rslt = await this.get("MenusV2", appcode);
    return rslt.Data;
  }
  async getMenu(srctypeid: number) {
    const result = this.get("Menu", ["null", srctypeid]);
    return result;
  }
}
