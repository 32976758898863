import { Injectable } from "@angular/core";
import { AppService } from "src/app/core/service/Base.Service";


@Injectable()
export class MenuScreenService extends AppService<any> {
  constructor() {
    super("Legalentity");
  }
}
