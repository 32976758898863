import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { BsModalModule } from "ng2-bs3-modal";
import { DataTableModule } from "primeng/components/datatable/datatable";
import { SharedModule } from "primeng/components/common/shared";
import { DropdownModule } from "primeng/primeng";

import { AgGridModule } from "ag-grid-angular/main";

import { LabelComponent } from "./label/label.component";
import { TextboxComponent } from "./textbox/textbox.component";
import { DateComponent } from "./date/date.component";
import { TextareaComponent } from "./textarea/textarea.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { RadiobuttonComponent } from "./radiobutton/radiobutton.component";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { ButtonComponent } from "./button/button.component";
import { TimeComponent } from "./time/time.component";
import { LookupComponent } from "./lookup/lookup.component";
import { SelectComponent } from "./select/select.component";
import { MessageBoxComponent } from "./messagebox/messagebox.component";

import { FormContainerComponent } from "./formcontainer/formcontainer.component";

import { AgGridComponent } from "./grid/aggrid.component";

import { ImageCropperComponent } from "ng2-img-cropper";

import { GridSelectComponent } from "./grid/editors/dropdown/gridselect.component";
import { IXSDropdownComponent } from "./dropdown/dropdown.component";
import { GridDatePickerComponent } from "./grid/editors/date/datecomponent";
import { GridNumericEditorComponent } from "./grid/editors/numeric/gridnumericcomponent";
import { GridTimePickerComponent } from "./grid/editors/time/gridtimecomponent";
import { IXSBsModalComponent } from "./ixsmodal/ixsmodal.component";
import { GridActionsComponent } from "./grid/editors/actions/actionscomponent";
import { IXSTextBoxComponent } from "./ixstextbox/ixstextbox.component";
import { IXSNumericComponent } from "./ixsnumeric/ixsnumeric.component";
import { IXSTextAreaComponent } from "./ixstextarea/ixstextarea.component";
import { IXSCheckBoxComponent } from "./ixscheckbox/ixscheckbox.component";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { TextMaskModule } from "angular2-text-mask";
import { MoodEditorComponent } from "./grid/editors/mood/moodeditor.component";
import { MoodRendererComponent } from "./grid/editors/mood/moodrenderer.component";
import { IxsloaderComponent } from "./ixsloader/ixsloader.component";
import { ToastyModule } from "ng2-toasty";
import { GridCheckBoxComponent } from "./grid/editors/checkbox/checkboxcomponent";
import { IxsReportComponent } from "./ixs-report/ixs-report.component";
import { IxsReportModule } from "./ixs-report/ixs-report.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DataTableModule,
    SharedModule,
    ToastyModule.forRoot(),
    TextMaskModule,
    DropdownModule,
    AmazingTimePickerModule,
    BsModalModule,
    AgGridModule.withComponents([
      GridSelectComponent,
      GridNumericEditorComponent,
      GridDatePickerComponent,
      GridTimePickerComponent,
      GridActionsComponent,
      MoodEditorComponent,
      MoodRendererComponent,
      GridCheckBoxComponent
    ])
  ],
  declarations: [
    LabelComponent,
    TextboxComponent,
    DateComponent,
    TextareaComponent,
    CheckboxComponent,
    RadiobuttonComponent,
    ButtonComponent,
    TimeComponent,
    SelectComponent,

    AgGridComponent,
    GridSelectComponent,
    GridNumericEditorComponent,
    GridDatePickerComponent,
    GridTimePickerComponent,
    GridActionsComponent,
    MoodEditorComponent,
    MoodRendererComponent,
    GridCheckBoxComponent,

    ImageCropperComponent,

    LookupComponent,
    ToolbarComponent,
    FormContainerComponent,
    MessageBoxComponent,
    IXSDropdownComponent,
    IXSBsModalComponent,
    IXSTextBoxComponent,
    IXSNumericComponent,
    IXSTextAreaComponent,
    IXSCheckBoxComponent,
    IxsloaderComponent
  ],
  exports: [
    TextboxComponent,
    DateComponent,
    TextareaComponent,
    CheckboxComponent,
    RadiobuttonComponent,
    ToolbarComponent,
    ButtonComponent,
    TimeComponent,
    LookupComponent,
    SelectComponent,
    GridNumericEditorComponent,
    GridDatePickerComponent,
    GridTimePickerComponent,
    GridActionsComponent,
    MoodEditorComponent,
    MoodRendererComponent,
    GridCheckBoxComponent,

    AgGridComponent,
    GridSelectComponent,

    ImageCropperComponent,

    FormContainerComponent,
    MessageBoxComponent,
    IXSDropdownComponent,
    IXSBsModalComponent,
    IXSTextBoxComponent,
    IXSNumericComponent,
    IXSTextAreaComponent,
    IXSCheckBoxComponent,
    IxsloaderComponent
  ]
})
export class ComponentModule {}
