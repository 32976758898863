// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { PostalregisterdetailComponent } from './postalregisterdetail.component';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ReportViewerModule } from "src/app/report-viewer/report-viewer.module";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    imports: [
        BrowserModule, ReportViewerModule, NgxErrorsModule, FormsModule, ReactiveFormsModule
    ],
    declarations: [
        PostalregisterdetailComponent,
    ],
    exports: [
        PostalregisterdetailComponent,
    ]
})
export class PostalregisterdetailModule {

}
