

import { AppService } from "src/app/core/service/Base.Service";
import { Injectable } from "@angular/core";

@Injectable()
export class OrgLegalentityService extends AppService<any>{

        constructor(){
            super('Legalentity')
        }
       
}