import { Component, OnInit, Input } from '@angular/core';
import { BaseEditor } from '../baseEditor/base.editor';
import * as _ from 'lodash';

@Component({
	selector: 'ixs-select',
	templateUrl: 'select.component.html'
})
export class SelectComponent extends BaseEditor {
	_value: string;
	_data: any[];

	@Input()
	get data(): any[] {
		return this._data || [];
	}

	set data(data: any[]) {
		this._data = data;

		this.updateData();
	}

	@Input()
	get value(): string {
		return this._value;
	}

	set value(value: string) {
		this._value = value;

		this.updateData();
	}

	@Input()
	label: string;

	@Input()
	formControlName: string;

	constructor() {
		super();
	}

	private updateData() {
		if (this._value && this._data) {
			_.map(this._data, x => {
				x.value = x[this._value];
				x.label = x[this.label];
			});
		}
	}
}
