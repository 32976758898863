import { Injectable } from "@angular/core";
import { AppService } from "src/app/core/service/Base.Service";

@Injectable()
export class FOPurposeService extends AppService<any>{

    constructor() {
        super('FOPurpose');
    }

    async getPurposeType() {
        const result = await this.get('Purpose');
        return result;
    }
}