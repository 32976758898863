import {
  Input,
  Component,
  Output,
  ViewChild,
  ContentChild,
  forwardRef,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import { GridOptions } from "ag-grid/dist/lib/entities/gridOptions";
import { RowDataTransaction } from "ag-grid/dist/lib/rowModels/inMemory/inMemoryRowModel";
import { GridActionsComponent } from "./editors/actions/actionscomponent";
import { IXSBsModalComponent } from "../ixsmodal/ixsmodal.component";
import { Subscription } from "rxjs/Subscription";
import { NavModalComponent } from "../../core/component/NavModal.Component";
import { Enum, GridType, modalArgs, addRow, customButton } from "../config";
import { MessageBoxComponent } from "../messagebox/messagebox.component";
import { ColDef } from "ag-grid/main";
import { ToolbarEvent } from "../../core/events/toolbar.event";
import { AppConstants } from "../../app.constant";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "ixs-aggrid",
  templateUrl: "aggrid.component.html",
  styleUrls: ["ixs-grid-theme.css"]
})
export class AgGridComponent implements AfterViewInit {
  toolbarEvent: ToolbarEvent;

  @Input()
  hideAdd: boolean;

  @Input()
  hideDelete: boolean;

  private _pagesize: string = "10";

  @Input()
  name: string;
  get pageSize(): string {
    return this._pagesize;
  }

  set pageSize(value: string) {
    this._pagesize = value;

    this.changePageSize();
  }

  //@Input()
  //name: string;

  constructor() {
    this.toolbarEvent = AppConstants.injector.get(ToolbarEvent);
  }

  ngAfterViewInit(): void {
    this.myscreen.screen = this.screen;

    if (this.options.api) {
      this.options.api.hideOverlay();
      this.options.enableFilter = this.enableFilter;
      this.options.suppressNoRowsOverlay = this.hideNoRecordOverlay;

      if ((this.data instanceof Array && this.data.length === 0) || !this.data)
        this.options.api.showNoRowsOverlay();
    }
  }

  @Output()
  actionOpen: EventEmitter<modalArgs> = new EventEmitter<modalArgs>();

  @Output()
  actionClose: EventEmitter<modalArgs> = new EventEmitter<modalArgs>();

  @Output()
  toolbarClick: EventEmitter<customButton> = new EventEmitter<customButton>();

  @ViewChild("agGrid")
  private agGrid;

  @Input()
  hideNoRecordOverlay: boolean = false;

  public _data: any;

  @ViewChild(forwardRef(() => IXSBsModalComponent))
  myscreen: IXSBsModalComponent;
  @ContentChild("ngContent") private screen: NavModalComponent<any>;

  @Input()
  get data(): any {
    return this._data;
  }
  set data(data: any) {
    if (data) {
      for (var index = 0; index < data.length; index++) {
        data[index].lineindex = index + 1;
      }
      this.generateIndex(0);
    }

    this._data = data;
  }

  @Input()
  get selectedData(): any {
    return this.options.api.getSelectedRows();
  }
  set selectedData(data: any) {}

  public _columns: ColDef[];
  @Input()
  get columns(): any {
    return this._columns;
  }
  set columns(columns: any) {
    if (columns) {
      if (!columns.filter(o => o.field === "lineindex")[0]) {
        columns.unshift({
          headerName: "S.No",
          field: "lineindex",
          width: 80
        });
      }

      if (
        this.type === GridType.selectionGrid ||
        this.type === GridType.inputGrid
      ) {
        if (!columns.filter(o => o.field === "selectedCol")[0]) {
          columns.unshift(this.selColumn);
        }
      }

      // columns.forEach(element => {

      //     if (element.field != 'selectedCol' && element.field != 'lineindex') {
      //         if (element.editable == false) {
      //             element['cellStyle'] = { color: 'red', backgroundColor: '#eee', position: 'unset' }
      //         }
      //         else {
      //             element['cellStyle'] = { position: 'unset' }
      //         }

      //     } else {
      //         element['cellStyle'] = { position: 'unset' }
      //     }

      // });
    }

    this._columns = columns;
    this.setCustomColumns();
  }

  private setCustomColumns() {
    if (this.columns) {
      // if (this.columns.filter(o => o.field === '...')[0]) {

      //     const extraCol = this.columns.filter(o => o.field === '...')[0];
      //     if (extraCol) {
      //         const colIndex = this.columns.indexOf(extraCol);
      //         if (colIndex !== -1) {
      //             this.columns.splice(colIndex, 1);
      //         }
      //     }

      // }

      if (this.type === GridType.actionsGrid) {
        if (!this.columns.filter(o => o.field === "actionid")[0]) {
          this.columns.push(this.actionColumn);
        }
      }

      // if (!this.columns.filter(o => o.field === '...')[0]) {
      //     this.columns.push({
      //         headerName: '', field: '...'
      //     });
      // }

      this.columns.forEach(element => {
        if (element.field !== "selectedCol" && element.field !== "lineindex")
          element["cellStyle"] = { padding: "0px !important" };
      });
    }
  }

  _type: number = GridType.inputGrid;
  @Input()
  get type(): number {
    return this._type;
  }
  set type(type: number) {
    this._type = type;

    this.setCustomColumns();
  }

  @Input()
  addRowAt = addRow.bottom;

  @Input()
  enableFilter: boolean;

  height = "313px";
  index: Number = 0;

  public options: GridOptions = <GridOptions>{
    singleClickEdit: true,
    stopEditingWhenGridLosesFocus: true,
    suppressTabbing: false,
    rowSelection: "multiple",
    domLayout: "normal",
    suppressLoadingOverlay: false,
    suppressNoRowsOverlay: this.hideNoRecordOverlay,
    suppressRowClickSelection: true,
    context: this,
    //paginationPageSize: 10,
    // paginationAutoPageSize: true,
    //rowModelType: 'pagination',
    // rowModelType: 'infinite',
    // pagination: true,
    //suppressPaginationPanel:true
    //enableFilter: this.enableFilter,
    enableFilter: this.enableFilter
  };

  private selColumn = {
    headerName: "",
    field: "selectedCol",
    width: 40,
    editable: false,
    hide: false,
    headerCheckboxSelection: true,
    checkboxSelection: true
  };

  private actionColumn = {
    headerName: "Actions",
    field: "actionid",
    width: 85,
    cellRendererFramework: GridActionsComponent,
    pinned: "left"
  };

  addRow() {
    if (this.options.api) {
      const node = this.options.api.getSelectedNodes()[0];
      const rowIndex = node
        ? node.rowIndex
        : this._data
        ? this._data.length - 1
        : 0;
      const rowData = { lineindex: rowIndex + 1 };

      this.options.api.updateRowData(<RowDataTransaction>{
        add: [rowData],
        addIndex: rowIndex + 1
      });

      if (this._data) {
        this._data.splice(rowIndex + 1, 0, rowData);
      } else {
        this._data = [rowData];
      }

      this.generateIndex(rowIndex);
    }
  }

  deleteRow() {
    if (this.options.api) {
      const nodes = this.options.api.getSelectedNodes();

      if (nodes && nodes.length > 0) {
        nodes.forEach(node => {
          if (node) {
            const rowIndex = node.rowIndex;
            this.options.api.updateRowData(<RowDataTransaction>{
              remove: [node.data]
            });

            if (this._data) {
              const index = this._data.indexOf(node.data);

              if (index !== -1) {
                this._data.splice(index, 1);
              }
            }

            // if (this._data.length > 0) {
            //     this.options.api.selectIndex(this._data.length > rowIndex ? rowIndex : this._data.length - 1, false, true);
            // }
          }
        });

        this.generateIndex(0);
      }
    }
  }

  updateRowData(rowData: any[] | any) {
    if (this.options.api) {
      this.options.api.updateRowData(<RowDataTransaction>{
        update: Array.isArray(rowData) ? rowData : [rowData]
      });
    }
  }

  addRowData(rowData: any[] | any, rowIndex: number = 0) {
    if (this.options.api) {
      this.options.api.updateRowData(<RowDataTransaction>{
        add: Array.isArray(rowData) ? rowData : [rowData],
        addIndex: rowIndex
      });

      this.generateIndex(0);
    }
  }

  generateIndex(startIndex: number) {
    startIndex = 1;
    if (this.options.api) {
      this.options.api.forEachNode(x => {
        x.setDataValue("lineindex", startIndex);
        startIndex++;
      });
    }
  }

  public display(mydata: [any], key: string) {
    setTimeout(() => {
      if (this.options.api) {
        this.options.api.deselectAll();

        if (this.options.api) {
          this.options.api.forEachNode(x => {
            const rowData = x.data;

            var row = mydata.filter(o => o[key] === rowData[key])[0];
            if (row) {
              Object.keys(row).map(name => {
                var ctrl = this.columns.filter(
                  o => o.field === name && o.allowClear === true
                )[0];
                if (ctrl) {
                  rowData[name] = undefined;
                  //x.setData(rowData);
                  // this._data.splice(x.rowIndex, rowData);
                }

                if (row[name]) {
                  rowData[name] = row[name];
                }

                if (rowData[name] && row[name] && rowData[name] === row[name]) {
                  x.setSelected(true);
                }
              });
            } else {
              Object.keys(rowData).map(name => {
                var ctrl = this.columns.filter(
                  o => o.field === name && o.allowClear === true
                )[0];
                if (ctrl) {
                  rowData[name] = undefined;
                  //x.setData(rowData);
                  // this._data.splice(x.rowIndex, rowData);
                }
              });
            }

            x.setData(rowData);

            if (this._data) {
              this._data.splice(x.rowIndex, rowData);
            }

            // mydata.forEach(item => {

            //     if (rowData[key] === item[key]) {

            //         Object.keys(item).map((field) => {
            //             rowData[field] = item[field]

            //         });

            //         // this.options.api.updateRowData(<RowDataTransaction>{
            //         //     update: [rowData],
            //         // });

            //         x.setData(rowData);
            //         x.setSelected(true);

            //         if (this._data) {

            //             this._data.splice(x.rowIndex, rowData);

            //         }

            //     }
            //     else {

            //     }

            // });
          });
        }
      }
    }, 100);

    this.generateIndex(0);
  }

  private selectAll() {
    this.options.api.selectAll();
  }

  private unselectAll() {
    this.options.api.deselectAll();
  }

  async openScreen() {
    this.startLoading();
    this.myscreen.screen.myForm.enable();
    this.index = -1;

    var rowData = null;

    if (this.index !== -1) {
      rowData = this.options.api.getRowNode(this.index.toString()).data;
    }

    this.actionOpen.emit(<modalArgs>{
      modal: this.myscreen,
      rowData: rowData,
      cancel: false
    });

    await this.myscreen.open();
    this.myscreen.screen.service.clearCondition();
    this.myscreen.mode = Enum.Mode.New;
    await this.myscreen.screen.clear();
    this.stopLoading();
  }

  Open(event: CustomEvent) {}

  Close(event: CustomEvent) {
    const modal: IXSBsModalComponent = event.detail;

    var data = modal.screen.model;

    var modalArgs: modalArgs = <modalArgs>{
      modal: modal,
      rowData: data,
      cancel: false
    };

    this.actionClose.emit(modalArgs);

    modal.screen.service.clearCondition();

    if (modal.mode === Enum.Mode.New) {
      const rowIndex =
        this.addRowAt === addRow.top ? 0 : this.options.rowData.length + 1;

      this.addRowData(data, rowIndex);

      if (this._data) {
        this._data.splice(rowIndex, 0, data);
      } else {
        this._data = [data];
      }

      this.generateIndex(0);
    } else if (modal.mode === Enum.Mode.Edit) {
      var rowNode = this.options.api.getRowNode(this.index.toString());

      rowNode.setData(data);

      this.generateIndex(0);
    }

    var rowData = null;

    if (this.index !== -1) {
      rowData = this.options.api.getRowNode(this.index.toString()).data;
    }
  }

  get primeryKeyField(): string {
    const primeryColumn = this.columns.filter(o => o.isPrimeryKey === true)[0];
    if (!primeryColumn) {
      console.error("Primery Column not defined");
      return null;
    }
    return primeryColumn.field;
  }

  private overlayNoRowsTemplate =
    "<img src='assets/Images/norecordfoundblue.png' style='height: 195px; '></img>";

  changePageSize() {
    const pageSize = Number(this.pageSize);
    this.height = 31.3 * pageSize + "px";
  }

  startLoading() {
    if (this.toolbarEvent) this.toolbarEvent.boardcast("start");
  }

  stopLoading() {
    if (this.toolbarEvent) this.toolbarEvent.boardcast("stop");
  }

  @Input()
  customButton: any = [];

  customButtonClick(item: customButton) {
    this.toolbarClick.emit(item);
  }
}
