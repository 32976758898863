import { Component, OnInit } from "@angular/core";

@Component({
  selector: "page-container",
  templateUrl: "../shell-templates/page-container.component.html"
})
export class PageContainerComponent {
  constructor() {}

  ngOnInit(): void {}
}
