import { Component } from '@angular/core';
import { NavComponent } from 'src/app/core/component/Nav.Component';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MenuScreenService } from './menu-screen.service';
import { GenEnum } from 'src/app/Shared/general.enum';

@Component({
    // moduleId: module.id,
    selector: 'menuscreen',
    templateUrl: 'menu-screen.component.html',
    styleUrls: ['menu-screen.component.scss']
})
export class MenuScreenComponent extends NavComponent<any> {
    menuid: number;
    children: any = [];
    menucollection: any = [];
    routeid: any;

    constructor(private _MenuScreenService: MenuScreenService,
        public router: Router,
        private route: ActivatedRoute
    ) {
        super(_MenuScreenService);
    }

    async ngOnInit() {

        this.route.queryParams.subscribe(params => {

            this.menuid = Number(params.menuid);
            this.menucollection = JSON.parse(params.menucollection);


            if (this.menuid !== undefined) {

                this.getChildrens();

            }
        });
        // this.url = location.pathname;

        let navigationExtras: NavigationExtras = {
            queryParams: {
                menuid: this.menuid
            },
            replaceUrl: false
        };

    }
    private height: any = '0px';
    getChildrens() {

        this.children = [];
        var data = this.menucollection.filter(o => o.id === this.menuid)[0];
        if (data) {

            this.setTitleNIcon(data.label, data.webicon);
            this.children = data.children;

            if (this.children) {
                var maxlenght = 0;

                this.children.forEach(element => {

                    if (element.children && element.children.Lenght > maxlenght) {
                        maxlenght = element.children.maxlenght;
                    }
                    this.height = (maxlenght * 26);
                });
            }

        }

    }
    changeicon(event) {
        if (event.target.classList.contains('fa-star-o'))
            event.target.classList.replace('fa-star-o', 'fa-star')
        else if (event.target.classList.contains('fa-star'))
            event.target.classList.replace('fa-star', 'fa-star-o')

    }


    private navigateRoute(item: any) {

        if (item.menucatid === GenEnum.MenuCategory.Screens) {

            this.navigate("/" + item.controllerId);
        } else if (item.menucatid === GenEnum.MenuCategory.Reports) {

            if (item.utreporthdr && item.utreporthdr.code) {

                let navigationExtras: NavigationExtras = {
                    queryParams: {
                        menuid: this.menuid,
                    },
                    replaceUrl: false
                };

                this.router.navigate(["/" + item.utreporthdr ? item.utreporthdr.code : ""], navigationExtras);

            }
            else {
                this.showErrors("Screen not found");
            }
        }


    }

}
